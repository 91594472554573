import { addressToString } from "../../utils/formatting";
import { EligibilityOutcome } from "../Property";
import { CurrentRepair, ReportContactDetails } from "../Repair/Repair";
import TelecomsAddress from "../shared/Address/TelecomsAddress";
import { RaisedBy } from "../shared/RaisedBy";
import { Statuses } from "../shared/Statuses";
import { Customer } from "./Customer";
import { Documents } from "./../Repair/Repair";
import { CustomerName } from "../Customer";

export class WorksOrderRequest {
  public statuses?: Statuses;
  public identifiers?: {
    worksOrderIdentifier?: string;
    originalWorkOrderIdentifier?: string;
    jobNumber?: string | null;
    contractorSiteIdentifier?: string | null;
    customerIdentifier?: string | null;
    crimeReferenceNumber?: string | null;
    asset?: {
      assetIdentifier?: string;
      premisesUniqueReference?: string;
    };
    documents?: Documents;
  };
  public contractor?: {
    name?: string;
    phoneNumber?: string;
  };
  public asset?: {
    type?: {
      assetTypeId?: string | number;
      assetTypeName?: string;
    };
    fullAddress?: string;
    isInDefect?: boolean
  };
  public job?: {
    location?: {
      locationId?: string;
      location?: string;
    };
    trade?: {
      trade?: string;
    };
    cause?: {
      causeId?: number;
      cause?: string;
    } | null;
    sorCode?: string;
    description?: string;
    repairReason?: string;
    priority?: string;
    additionalInformation?: string | null;
  };
  public appointments?: [
    { appointmentStartDate: string; appointmentEndDate: string }
  ];
  public raisedBy?: RaisedBy;
  public reporter?: {
    person?: {
      name: CustomerName | null | undefined
    } | null;
    addresses?: {
      telecomsAddresses?: TelecomsAddress[];
    } | null;
    reporterToBeContacted?: boolean;
    organisation?: string | null;
    reporterRelationship?: {
      reportedById?: number;
      reportedBy?: string;
    };
    reportingMethod?: {
      reportingMethodId?: number;
      reportingMethod?: string;
    };
  } | null;
  public eligibilityOutcomes?: EligibilityOutcome[];
  public customer?: Customer | null;
  public raisedDate?: string;
  public completedDate?: string | null;
  public eTag: string = "eTag";

  // TODO: create constructor that takes currentRepair and transforms into ApiRepair\
  constructor(currentRepair: Partial<CurrentRepair>) {
    this.statuses =
      currentRepair.isDraft !== undefined ||
        currentRepair.isSupplementary !== undefined ||
        currentRepair.isVandalism !== undefined ||
        currentRepair.isEmergency !== undefined ||
        currentRepair.isOutOfHours !== undefined ||
        currentRepair.riskToHumanLife !== undefined ||
        currentRepair.isCovidSelfIsolated !== undefined ||
        currentRepair.isRecall !== undefined ||
        currentRepair.status !== undefined ||
        currentRepair.isEMA !== undefined ||
        currentRepair.isCatalyst !== undefined ||
        currentRepair.isPotentialDuplicate !== undefined ||
        currentRepair.isSubmitted !== undefined
        ? {
          isDraft: currentRepair.isDraft,
          isSupplementary: currentRepair.isSupplementary,
          isVandalism: currentRepair.isVandalism,
          isEmergency: currentRepair.isEmergency,
          isOutOfHours: currentRepair.isOutOfHours,
          isRiskToHumanLife: currentRepair.riskToHumanLife,
          status: currentRepair.status,
          isRecall: currentRepair.isRecall,
          isCovidSelfIsolated: currentRepair.isCovidSelfIsolated,
          isEMA: currentRepair.isEMA,
          isCatalyst: currentRepair.isCatalyst,
          isPotentialDuplicate: currentRepair.isPotentialDuplicate,
          isSubmitted: currentRepair.isSubmitted
        }
        : undefined;
    this.identifiers =
      currentRepair.id ||
        currentRepair.originalWorkOrderIdentifier ||
        currentRepair.jobNo ||
        currentRepair.contractor ||
        currentRepair.crimeReferenceNumber ||
        currentRepair.crimeReferenceNumber === "" ||
        currentRepair.premisesId ||
        currentRepair.premisesUniqueReference
        ? {
          worksOrderIdentifier: currentRepair.id,
          originalWorkOrderIdentifier:
            currentRepair.originalWorkOrderIdentifier,
          jobNumber: currentRepair.jobNo,
          customerIdentifier: currentRepair.customerIdentifier,
          contractorSiteIdentifier: currentRepair.contractor,
          crimeReferenceNumber: currentRepair.crimeReferenceNumber,
          asset:
            currentRepair.premisesId || currentRepair.premisesUniqueReference
              ? {
                assetIdentifier: currentRepair.premisesId,
                premisesUniqueReference:
                  currentRepair.premisesUniqueReference,
              }
              : undefined,
          documents: currentRepair.documents
            ? currentRepair.documents
            : undefined,
        }
        : undefined;

    this.contractor =
      currentRepair.contractor ||
        currentRepair.contractorPhoneNumber ||
        currentRepair.contractorBuilderName ||
        currentRepair.contractorBuilderPhone
        ? {
          name:
            currentRepair.contractorBuilderName ||
            currentRepair.contractorName,
          phoneNumber:
            currentRepair.contractorBuilderPhone ||
            currentRepair.contractorPhoneNumber ||
            "",
        }
        : undefined;

    this.asset =
      currentRepair.premisesTypeId || currentRepair.premisesAddress
        ? {
          type: currentRepair.premisesTypeId
            ? {
              assetTypeId: currentRepair.premisesTypeId,
            }
            : undefined,
          fullAddress: currentRepair.premisesAddress
            ? addressToString(currentRepair.premisesAddress)
            : undefined,
          isInDefect: currentRepair?.isInDefect ?
            currentRepair.isInDefect : false,
        }
        : undefined;

    this.job = {
      location: currentRepair.repairLocationId
        ? {
          locationId: currentRepair.repairLocationId,
          location: currentRepair.repairLocation,
        }
        : undefined,
      trade: currentRepair.trade
        ? {
          trade: currentRepair.trade,
        }
        : undefined,
      cause:
        currentRepair.repairCause ||
          currentRepair.repairCauseId ||
          currentRepair.repairCauseId === 0
          ? {
            cause: currentRepair.repairCause,
            causeId: currentRepair.repairCauseId,
          }
          : undefined,
      sorCode: currentRepair.sorCode,
      description: currentRepair.description,
      repairReason: currentRepair.repairReason,
      priority:currentRepair.priority,
      additionalInformation: currentRepair.additionalInformation,
    };
    this.appointments =
      currentRepair.appointmentEndDate || currentRepair.appointmentStartDate
        ? [
          {
            appointmentStartDate: currentRepair.appointmentStartDate,
            appointmentEndDate: currentRepair.appointmentEndDate,
          },
        ]
        : undefined;
    this.raisedBy = currentRepair.raisedBy
      ? new RaisedBy(
        currentRepair.raisedBy?.person.name,
        currentRepair?.raisedBy?.address?.digitalAddresses
      )
      : undefined;
      this.reporter =
      currentRepair.reporterContactDetails ||
      currentRepair.reporterToBeContacted ||
      currentRepair.reportedById ||
      currentRepair.reportedBy ||
      currentRepair.reportingMethodId ||
      currentRepair.reportingMethod
        ? {
            person: {
              name: currentRepair?.reporterContactDetails?.name,
            },
            addresses: {
              telecomsAddresses: currentRepair.reporterContactDetails
                ?.contactNumber
                ? [
                    {
                      number:
                        currentRepair.reporterContactDetails?.contactNumber ||
                        "",
                      deviceId: null,
                      dialingCode: null,
                      countryCode: null,
                      telecomsAddressType: "telephone",
                      isPreferred: true,
                    },
                  ]
                : [],
            },
            reporterToBeContacted:
              currentRepair.reporterContactDetails?.reporterToBeContacted,
            organisation:
              currentRepair.reporterContactDetails?.organisation || "",
            reporterRelationship:
              currentRepair.reportedById || currentRepair.reportedBy
                ? {
                    reportedById: currentRepair.reportedById,
                    reportedBy: currentRepair.reportedBy,
                  }
                : undefined,
            reportingMethod:
              currentRepair.reportingMethodId || currentRepair.reportingMethod
                ? {
                    reportingMethodId: currentRepair.reportingMethodId,
                    reportingMethod: currentRepair.reportingMethod,
                  }
                : undefined,
          }
        : undefined;
    this.customer =
      currentRepair.customerName?.title ||
        currentRepair.customerName?.givenName ||
        currentRepair.customerName?.surname ||
        currentRepair.chargeCustomer ||
        currentRepair.chargeCustomer === false ||
        currentRepair.isVulnerable ||
        currentRepair.notifyAll ||
        currentRepair.notifyAll === false ||
        currentRepair.staffContactDigitalAddresses
        ? {
          person:
            currentRepair.customerName?.title ||
              currentRepair.customerName?.givenName ||
              currentRepair.customerName?.surname ||
              currentRepair.chargeCustomer ||
              currentRepair.chargeCustomer === false
              ? {
                name:
                  currentRepair.customerName?.title ||
                    currentRepair.customerName?.givenName ||
                    currentRepair.customerName?.surname
                    ? {
                      title: currentRepair.customerName?.title,
                      givenName: currentRepair.customerName?.givenName,
                      surname: currentRepair.customerName?.surname,
                    }
                    : undefined,
                chargeCustomer: currentRepair.chargeCustomer,
              }
              : undefined,

          vulnerability:
            currentRepair.isVulnerable != null ?
              {
                isVulnerable: currentRepair.isVulnerable,
                vulnerableResponseText: currentRepair.VulnerableResponseText,
              } :
              null,
          notification:
            currentRepair.notifyAll ||
              currentRepair.notifyAll === false ||
              currentRepair.staffContactDigitalAddresses
              ? {
                notifyAll: currentRepair.notifyAll,
                address: currentRepair.staffContactEmails
                  ? {
                    digitalAddresses: this.buildDigitalAddresses(
                      currentRepair.staffContactEmails
                    ),
                  }
                  : undefined,
              }
              : undefined,
        }
        : undefined;

    this.eligibilityOutcomes = currentRepair.eligibilityOutcomes;
    this.raisedDate = currentRepair.raisedDate;
    this.completedDate = currentRepair.completedDate;
    this.eTag = currentRepair.eTag || "eTag";
  }
  public buildDigitalAddresses(staffContactEmails: string[]) {
    return staffContactEmails.map((email) => {
      return {
        address: email,
        digitalAddressType: "email",
        isPreferred: false,
      };
    });
  }

  public createWorkOrderParameter(workOrderRepair: CurrentRepair) {
    const woRequestParameters: Partial<CurrentRepair> = {
      repairReason: workOrderRepair.repairReason,
      priority:workOrderRepair.priority,
      description: workOrderRepair.description,
      additionalInformation: workOrderRepair.additionalInformation,
      isEmergency: workOrderRepair.isEmergency,
      isSupplementary: workOrderRepair.isSupplementary,
      isVandalism: workOrderRepair.isVandalism,
      isCovidSelfIsolated: workOrderRepair.isCovidSelfIsolated,
      isVulnerable: workOrderRepair.isVulnerable,
      premisesUniqueReference: workOrderRepair.premisesUniqueReference,
      premisesTypeId: workOrderRepair.premisesTypeId,
      premisesAddress: workOrderRepair.premisesAddress,
      premisesId: workOrderRepair.premisesId,
      sorCode: workOrderRepair.sorCode,
      customerName: workOrderRepair.customerName,
      customerIdentifier: workOrderRepair.customerIdentifier,
      originalWorkOrderIdentifier: workOrderRepair.id,
      eTag: workOrderRepair.eTag,
      eligibilityOutcomes: workOrderRepair.eligibilityOutcomes,
      assetIdentifier: workOrderRepair.assetIdentifier,
      repairLocationId: workOrderRepair.repairLocationId,
      notifyAll: workOrderRepair.notifyAll,
      reportingMethodId: workOrderRepair.reportingMethodId,
      reportingMethod: workOrderRepair.reportingMethod,
      repairCause: workOrderRepair.repairCause,
      repairCauseId: workOrderRepair.repairCauseId,
      isCatalyst: workOrderRepair.isCatalyst,
      isOutOfHours: workOrderRepair.isOutOfHours,
      isEMA: workOrderRepair.isEMA

    };
    return woRequestParameters;
  }

  public updateWorkOrderParameter(workOrderRepair: CurrentRepair) {
    const woRequestParameters: Partial<CurrentRepair> =
      this.createWorkOrderParameter(workOrderRepair);
    if (
      workOrderRepair.crimeReferenceNumber != "" &&
      workOrderRepair.crimeReferenceNumber != "No"
    ) {
      woRequestParameters.crimeReferenceNumber =
        workOrderRepair?.crimeReferenceNumber;
      woRequestParameters.repairCause = workOrderRepair?.repairCause;
      woRequestParameters.repairCauseId = workOrderRepair?.repairCauseId;
    }
    else {
      woRequestParameters.crimeReferenceNumber =
        "";
    }

    woRequestParameters.reporterContactDetails = new ReportContactDetails();
    woRequestParameters.reporterContactDetails.name =
      workOrderRepair?.reporterContactDetails?.name;
    woRequestParameters.reportingMethod = workOrderRepair.reportingMethod;
    woRequestParameters.reportingMethodId = workOrderRepair.reportingMethodId;
    woRequestParameters.reportedBy = workOrderRepair.reportedBy;
    woRequestParameters.reportedById = workOrderRepair.reportedById;
    woRequestParameters.reportingMethodId = workOrderRepair.reportingMethodId;
    if (workOrderRepair?.reporterContactDetails?.reporterToBeContacted != undefined) {
      woRequestParameters.reporterContactDetails.reporterToBeContacted = workOrderRepair?.reporterContactDetails.reporterToBeContacted
    }

    if (woRequestParameters?.reporterContactDetails?.name != undefined &&
      woRequestParameters?.reporterContactDetails?.name != null) {
      woRequestParameters.reporterContactDetails.reporterToBeContacted = true
    }
    if (
      workOrderRepair?.reporterContactDetails?.contactNumber != undefined &&
      workOrderRepair?.reporterContactDetails?.contactNumber != ""
    ) {
      woRequestParameters.reporterContactDetails.contactNumber =
        workOrderRepair?.reporterContactDetails?.contactNumber;
      woRequestParameters.reporterContactDetails.reporterToBeContacted = workOrderRepair.reporterContactDetails.reporterToBeContacted

    }

    if (workOrderRepair.isDraft) {
      woRequestParameters.isDraft = !workOrderRepair.isDraft;
    }
    
    woRequestParameters.isSubmitted = workOrderRepair?.isSubmitted ?? null;

    if (workOrderRepair.isOutOfHours) {
      woRequestParameters.isOutOfHours = workOrderRepair.isOutOfHours;
    }

    if (workOrderRepair.isEMA) {
      woRequestParameters.isEMA = workOrderRepair.isEMA;
    }
    if (workOrderRepair.isCatalyst) {
      woRequestParameters.isCatalyst = workOrderRepair.isCatalyst;
    }
    if (workOrderRepair.isInDefect) {
      woRequestParameters.isInDefect = workOrderRepair.isInDefect;
    }

    // woRequestParameters.isEmergency = workOrderRepair.isEmergency
    woRequestParameters.isPotentialDuplicate =
      workOrderRepair.isPotentialDuplicate;
    woRequestParameters.isVulnerable = workOrderRepair.isVulnerable;
    woRequestParameters.VulnerableResponseText =
      workOrderRepair.VulnerableResponseText;

    woRequestParameters.contractor = workOrderRepair.contractor;
    woRequestParameters.contractorName = workOrderRepair.contractorName;
    woRequestParameters.contractorPhoneNumber =
      workOrderRepair.contractorPhoneNumber;
    return woRequestParameters;
  }
}
