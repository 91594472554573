import { RescheduleAppointmentModel } from '../../models/RescheduleAppointment'
import { isUndefinedNullOrEmpty } from '../../utils/translation/helper'
import { ApiError } from '../ApiError'
import { createBrowserHistory } from "history";
export class CreateAppointmentParams {
  
  public reasonCode: string = ''
  public remarks: string = '' 
  public jobNumber: string = ''  
  public diaryCode: string = '';
  public appointmentFromDate: Date = new Date()
  public appointmentToDate: Date = new Date()
  public externalCreateDateTime: Date = new Date()
  public isPreferences: boolean = false
  public externalReferenceId: string = ''
  public availabilityType: string = ''  
  public isInsideSla: boolean = false
  public metadata: any[] = []
}

const history = createBrowserHistory();
const appointmentBaseUrl = process.env.REACT_APP_APPOINTMENT_BASE_URL || ''
const apimBaseUrl = process.env.REACT_APP_ADDRESSAPI_BASE_URL  || '';
const ergoApiBaseUrl = process.env.REACT_APP_ERGOAPI_BASE_URL  || '';
const apimKey = process.env.REACT_APP_APIM_KEY;
const origin = process.env.REACT_APP_WEB_APP_HOSTNAME;
const forwarded_for = process.env.REACT_APP_X_FORWARDED_FOR;
const channel_identifier = process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
const agent_identifier = process.env.REACT_APP_X_AGENT_IDENTIFIER;
const if_match = process.env.REACT_APP_IF_MATCH;
const cache_control = process.env.REACT_APP_CACHE_CONTROL;

const request = (method: string) => (basePath: string) => async (
  path?: string,
  data?: object,
  metadata?: any
) => {
  const _channel = localStorage.getItem("channel") || "";
  const _customerId = localStorage.getItem("customerId") || "";
  const _corelationId = localStorage.getItem("corelationId") || "";
  const _sessionId = localStorage.getItem("sessionId") || "";
  const opt: any = {
    method,
    headers: {
      Authorization: "",
      "Ocp-Apim-Subscription-Key": apimKey,
      Origin: origin,
      "Cache-Control": cache_control,
      "if-Match": metadata?.eTag || if_match, 
      "x-channel-identifier": !isUndefinedNullOrEmpty(_channel)
        ? _channel
        : channel_identifier,
      "X-Correlation-Identifier": _corelationId,
      "x-session-identifier": _sessionId,
      "x-agent-identifier": !isUndefinedNullOrEmpty(_customerId)
        ? _customerId
        : agent_identifier,
      "x-forwarded-for": forwarded_for, 
      OperationGroupKey: metadata?.operationKey,
      ...(data && {
        'Content-Type': 'application/json',
      }),
    },
    ...(data && { body: JSON.stringify(data) }),
  }

  return fetch(`${basePath}${path}`, opt).then(
    async res => {
      const response = await res.json().catch(() => null)
      if (res.status === 401) {
        window.sessionStorage.clear();
        window.localStorage.clear()
        window.location.assign(window.location.origin)
        throw new ApiError({ status: res.status })
      } 
      
      if (!res.ok) {
        if (res.status === 412) {
          return { status: res.status , title : res.statusText }
         }
        //need to handle in case of not 200 and not 401       
        if (opt.method === "GET") {
          //handle other scenarios on UI        
        }
        else if ((opt.method === "POST" || opt.method === "PATCH")  && res.status === 500) {
          //handle scenario on UI
        }
        else {
            window.localStorage.clear();
            window.location.assign(window.location.origin);
            history.push("/genericerror");
            window.location.reload();
        }
      }
      if (!data) {
        // If it's not post, we inject the eTag that used to come as a part of the body, in the response.
        const eTagInHeader = res.headers.get('eTag')
        if (eTagInHeader) {
          return { ...response, eTag: eTagInHeader }
        }
      }
      return response
    },
    err => {
      throw new ApiError({ status: err.status, title: err.statusText })
    }
  )
}

export const post = request('POST')
export const get = request('GET')
export const patch = request("PATCH");

export const postAppointment = (workorderid : string,params: CreateAppointmentParams, operationKey: string, contractorName: string) =>
  post(apimBaseUrl)(
    `/customerselfserve/api/v1/works-orders/${workorderid}/appointments?contractorName=${contractorName}`,
    params,
    {
      operationKey,
    }
  )

export const getAvailabilityForAppointment = (workorderid : string, referenceNumber: string, operationKey: string ,contractorName: string, isExternalWorkorderRequired: boolean) =>
  get(
    apimBaseUrl
  )(
    `/customerselfserve/api/v1/works-orders/${workorderid}/appointments/availability?referenceNumber=${referenceNumber}&IsExternalWorkOrderRequired=${isExternalWorkorderRequired}&contractorName=${contractorName}`,
    undefined,
    { operationKey }
  )

  export const patchAppointment = (workorderid : string,params: RescheduleAppointmentModel) =>
  patch(ergoApiBaseUrl)(
    `/customerselfserve/api/v1/works-orders/${workorderid}/appointments`,
    params
  )