export class Helper {
  static ValidationRegex = {
    NumericOnly: /^[+0-9]+$/, // Regex for numeric input (phone number)
    AlphabetWithSpecialChars: /^[-A-Za-z' \b]+$/, // Regex for name input
    All_Special_Chars_Allowed: /^[A-Za-z0-9\s|.,_@+#%'\-()^`~=¬£€\\/[\]?:;"{}]*$/,//All Special Chars Allowed - .()%@:?@#%:"'',./?|]{}~`-=+
  };

  static LengthValidation = {
    CrimeReferenceNumber: {
      Minimum: 3,
      Maximum: 20,
    },
  };

  static validateMinLength(
    t: (key: string, params?: Record<string, any>) => string,
    value: string,
    fieldName: string,
    minLength: number
  ): string | null {
    if (value.length < minLength) {
      return t("minimum_length", {
        field: fieldName,
        min: minLength,
      });
    }
    return null;
  }

  static validateMaxLength(
    t: (key: string, params?: Record<string, any>) => string,
    value: string,
    fieldName: string,
    maxLength: number
  ): string | null {
    if (value.length > maxLength) {
      return t("maximum_length", {
        field: fieldName,
        max: maxLength,
      });
    }
    return null;
  }

  static validateInvalidCharacters(
    t: (key: string, params?: Record<string, any>) => string,
    value: string,
    fieldName: string,
    regex: RegExp
  ): string | null {
    if (!regex.test(value)) {
      return t("invalid_characters", {
        field: fieldName,
      });
    }
    return null;
  }
}
