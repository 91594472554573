import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "../../compositions/VandalismQuestion/index.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import * as api from "../../api";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../storybook/Button";
import Loader from "react-loader-spinner";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import BackModal from "../Modal/BackModal";
import {
  userChosenVandalismAdditionalInformation,
  userChosenVandalismQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  userChosenVulnerabilityAdditionalInformation,
  userChosenVulnerabilityQuestion,
  userChosenCovidQuestion,
  isEditState,
  resumeDraftRepair
} from "../../ducks/redux/actions/userChoices";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";
import { Helper } from "../../utils/Helper/HelperUtil";

interface VandalismQuestionProps extends RouteComponentProps<any> {}
const VandalismQuestion: React.FC<VandalismQuestionProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [eligible, setEligible] = useState("none");
  const [radioValue, setRadioValue] = useState("No");
  const [crimeRefValue, setCrimeRefValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttondisable, setButtonDisable] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const [error, setError] = useState("");

  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const userChosenVandalismQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );
  const userChosenVandalismAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.userChoicesReducer?.repairPriority
  );

  const resumeDraftRepairVal = useSelector(
    (state: any) => state.userChoicesReducer.resumeDraftRepair
  );
  const userResumeDraftDataCache = useSelector(
    (state: any) => state.userChoicesReducer.userResumeDraftDataCache
  );

   const selectedproperty = useSelector(
      (state: any) => state?.userChoicesReducer?.userChosenPropertyType
    );


  useEffect(() => {
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage");
      return;
    }
    if (userChosenVandalismQuestionBackButton) {
      setRadioValue(userChosenVandalismQuestionBackButton);
      setCrimeRefValue(
        userChosenVandalismQuestionBackButton === "No"
          ? ""
          : userChosenVandalismAdditionalInformationBackButton
      );
      setEligible(
        userChosenVandalismQuestionBackButton === "Yes" ? "block" : "none"
      );
    }
  }, [
    props.history,
    loggedInStatus,
    props.history.action,
    userChosenVandalismQuestionBackButton,
    userChosenVandalismAdditionalInformationBackButton,
  ]);

  const onChange = (e: any) => {
    const { value } = e.target;
    setCrimeRefValue(value);
    dispatch(userChosenVandalismAdditionalInformation(value));
    if (EditState) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    const invalidCharError = Helper.validateInvalidCharacters(
      t,
      value,
      "Crime reference number",
      Helper.ValidationRegex.All_Special_Chars_Allowed
    );
    setError(invalidCharError || "");
  };

  // update work order
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const validateForm = (): boolean => {
    if (radioValue === "Yes" && crimeRefValue && crimeRefValue.trim() !== "") {
      const minLengthError = Helper.validateMinLength(
        t,
        crimeRefValue,
        "Crime reference number",
        Helper.LengthValidation.CrimeReferenceNumber.Minimum
      );
      const maxLengthError = Helper.validateMaxLength(
        t,
        crimeRefValue,
        "Crime reference number",
        Helper.LengthValidation.CrimeReferenceNumber.Maximum
      );
      const invalidCharError = Helper.validateInvalidCharacters(
        t,
        crimeRefValue,
        "Crime reference number",
        Helper.ValidationRegex.All_Special_Chars_Allowed
      );
    
      const validationError = minLengthError || maxLengthError || invalidCharError;
      if (validationError) {
        setError(validationError);
        return false;
      }
    }
    setError("");
    return true;
  };

  const submitForm = async (): Promise<boolean> => {
    try {
      setIsLoading(true);
      setButtonDisable(true);
      workOrderObject.priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);

      if (radioValue === "Yes") {
        woRequestParameters.isVandalism = true;
        workOrderObject.isVandalism = true;
        woRequestParameters.crimeReferenceNumber = crimeRefValue;
        woRequestParameters.repairCause = "Vandalism or Damage";
        woRequestParameters.repairCauseId =
          RepairCauseId[
            woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        woRequestParameters.crimeReferenceNumber = "";
        woRequestParameters.isVandalism = false;
        workOrderObject.isVandalism = false;
        woRequestParameters.repairCause = "General Wear and Tear";
        woRequestParameters.repairCauseId =
          RepairCauseId[
            woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      }
      await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );

      dispatch(workOrderRepairObject(workOrderObject));
      return true;
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setError(t("Generic_Error_API"));
      return false;
    } finally {
      setIsLoading(false);
      setButtonDisable(false);
    }
  };
  const backButtonHandler = () => {
    dispatch(userChosenVandalismAdditionalInformation(""));
    dispatch(userChosenVandalismQuestion("No"));
    props.history.push("/repairadditionaldetails");
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    const isValid = validateForm();
    if (!isValid) {
      setCloseAfterSaving(true);
      return;
    }
    submitForm().then((isFormSaved) => {
      if (isFormSaved) {
        setCloseAfterSaving(true);
        props.history.replace("/customer360View");
        setTimeout(() => setCloseAfterSaving(false), 0);
      }
    });
    setCloseAfterSaving(true);
  };

  const callPageHandler = () => {
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    if (resumeDraftRepairVal) {
      const { vandalismQuestion, crimeReferenceNumber } =
        userResumeDraftDataCache;
      if (
        vandalismQuestion !== radioValue ||
        crimeReferenceNumber !== crimeRefValue
      ) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenVulnerabilityQuestion("No"));
        dispatch(userChosenVulnerabilityAdditionalInformation(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    submitForm().then((isFormValid) => {
      if (isFormValid) {
        
        // console.log("Will be called after 2 seconds");
        if (selectedproperty === "Property") {
          props.history.push("/vulnerabilityquestion");
        }
        else {
          props.history.push("/customerdetails");
        }
      }
    });
  };
  const handleRadioChange = (event: any) => {
    const selectedValue = event.target.value;

    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
      dispatch(userChosenVulnerabilityQuestion("No"));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }

    setRadioValue(selectedValue);
    dispatch(userChosenVandalismQuestion(selectedValue));

    if (selectedValue === "Yes") {
      setEligible("block");
    } else {
      setError("");
      setEligible("none");
      setCrimeRefValue("");
      dispatch(userChosenVandalismAdditionalInformation(""));
    }
  };

  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div
        className="container-fluid parent-footer repair-request p-0"
        id="vanQues-container"
      >
        <div className="padding-repair" id="vanQues-main-div">
          <div id="vanQues-div1">
            <h1 className="pt-40 header-size" id="vanQues-h1">
              {t("VandalismQuestion")}
            </h1>
            <div className="mt-30">
              <RadioGroup
                id="vanQues-radiogroup"
                role="radiogroup"
                name="radio-buttons-group"
                defaultValue={radioValue}
                data-testid="crimereradioValue"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  id="vanQues-label1"
                  value="Yes"
                  placeholder="Yes"
                  data-testid="yesRadio"
                  control={<Radio id="vanQues-radio1" />}
                  label="Yes"
                />
                <div id="vanQues-div2" style={{ display: eligible }}>
                  <input
                    id="vanQues-text"
                    type="text"
                    value={crimeRefValue}
                    data-testid="crimereferencebox"
                    name="crimereferencebox"
                    className={`txtBox-Input ${error ? "input-error" : ""}`}
                    placeholder="Crime reference number (optional)"
                    onChange={(e) => onChange(e)}
                    aria-label="Crime reference number (optional)"
                  ></input>
                  {error && (
                    <Message className="error-msg text-left">
                      {t(error)}
                    </Message>
                  )}
                </div>
                <FormControlLabel
                  id="vanQues-label2"
                  value="No"
                  data-testid="noRadio"
                  control={<Radio id="vanQues-radio2" />}
                  label="No"
                  placeholder="No"
                />
              </RadioGroup>
            </div>
          </div>
        </div>
        <div className="col-md-12 marginTop-auto" id="vanQues-main-btn">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair">
              <BackModal
                id="vanques-backmodal"
                back={() => backButtonHandler()}
              />
              <ExitModalAndSave
                id="vanQues-exitmodal"
                exit={() => exitButtonHandler()}
                saveDraftAndExit={() => saveDraftAndExit()}
                closeAfterSaving={closeAfterSaving}
              />
            </div>
            <div className="col text-end pt-24 padding-repair">
              <Button
                id="btn_vandalism"
                disabled={buttondisable}
                onClick={callPageHandler}
                className="primary-btn"
              >
                {t("continuen_button")}
              </Button>
            </div>
          </div>
        </div>

        <span>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (
            <>
              <div></div>
            </>
          )}
        </span>
      </div>
    </>
  );
};

export default VandalismQuestion;
