import React, { useContext, useEffect, useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboardForm } from "../../ducks/redux/actions/dashboardReducer.actions";
import {
  userChosenVulnerabilityQuestion,
  userChosenVulnerabilityAdditionalInformation,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails,
  isEditState,
  resumeDraftRepair,
  setNavHeaderText
} from "../../ducks/redux/actions/userChoices";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import Button from "../../storybook/Button";
import "../../compositions/VulnerabilityQuestion/index.scss";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import * as api from "../../api";
import { isUndefinedNullOrEmpty } from "../../utils/translation/helper";
import { FeatureFlags } from "../contexts/FeatureFlags";

import HeaderJourney from "../../storybook/Header/HeaderJourney";
import Message from "../../storybook/Message";

interface VulnerabilityQsProps extends RouteComponentProps<any> { }

const VulnerabilityQs: React.FC<VulnerabilityQsProps> = (props) => {
  const { t } = useTranslation();
  const { featureMedia }: any = useContext(FeatureFlags);
  const format = /[$^&*_\{};<>/]+/;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isError, setError] = React.useState("none");
  const [textvalue, setTextValue] = useState("");
  const [radiovalue, setRadioValue] = useState("No");
  const [isLoading, setIsLoading] = useState(false);
  const [buttondisable, setButtonDisable] = useState(false);
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const[BlockMsg,setBlockMsg]=useState<any>();
  const [blockLoading,setBlockLoading] =useState(false);

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );
  const EditState = useSelector(
    (state: any) => state.userChoicesReducer?.isEditState
  );

  const userChosenVulnerabilityQuestionBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );
  const userChosenVulnerabilityAdditionalInformationBackButton = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );
  const loggedInStatus = useSelector(
    (state: any) => state?.userChoicesReducer?.loginStatus
  );
  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject

  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );
  const resumeDraftRepairVal = useSelector((state: any) => state.userChoicesReducer.resumeDraftRepair);
  const userResumeDraftDataCache = useSelector((state: any) => state.userChoicesReducer.userResumeDraftDataCache);
  
  const userChosenEmergencyQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenEmergencyQuestion
  );

  const customerIdentifier = useSelector(
    (state: any) => state.addresses?.customerid
  );
  
  const workOrderfromState = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const userChosenSORValue = useSelector(
    (state: any) => state.userChoicesReducer.userChosenSOR
  );
  const userChosenDuplicateQuestion = useSelector(
    (state: any) => state?.userChoicesReducer?.userChosenDuplicateQuestion
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.userChoicesReducer?.repairPriority
  );
  

  useEffect(() => {
    if (isUndefinedNullOrEmpty(loggedInStatus)) {
      props.history.push("/startpage");
      return;
    } 
    setRadioValue(userChosenVulnerabilityQuestionBackButton);
    setTextValue(userChosenVulnerabilityAdditionalInformationBackButton);
    if(userChosenEmergencyQuestion === "Yes, I can provide access" || workOrderfromState.isEmergency){
      props.history.replace("/covidquestion");
    }
  }, []);

  useEffect(() => {
    if ((radiovalue === "Yes" && textvalue != "") || radiovalue === "No") {
      setError("none");
    }
    dispatch(userChosenVulnerabilityQuestion(radiovalue));
  }, [textvalue, radiovalue]);

  useEffect(() => {
    return () => {
      dispatch(setNavHeaderText("Contact Details"));
    };
  }, []);

  const updateSelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setButtonDisable(true);
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    setRadioValue(e.target.value);
    dispatch(
      updateDashboardForm({
        selected: e.target.value,
        textArea: textvalue,
      })
    );
    if (e.target.value === "No") {
      setTextValue("");
      dispatch(userChosenVulnerabilityAdditionalInformation(""));
    }
 
    const advancedPayload = {
      "isEmergency": workOrderfromState.isEmergency,
      "sor": userChosenSORValue,
      "isPotentialDuplicate": userChosenDuplicateQuestion.split("&")[2] ? true : (userChosenDuplicateQuestion=== t("duplicateNoneofTheAbove")) ?? false,
      "isVulnerable": e.target.value==="Yes" ?? false,
      "channel": "CustomerSelfService"
    }     
    try {
      setBlockLoading(true);
    let duplicateBlock = await api.postEnhancedRepairEligibility(advancedPayload);
    setBlockMsg(duplicateBlock?.isEligible); 
    if(duplicateBlock?.isEligible==false && e.target.value === "Yes"){
      setButtonDisable(true)
      setIsLoading(true);
    }  
    else if(duplicateBlock?.isEligible==true && e.target.value === "Yes"){
      setButtonDisable(false)
      setIsLoading(true);
    }  
    else if (e.target.value === "No") {
      setButtonDisable(false);
    }
    setBlockLoading(false);
  }
  catch(e) {
    console.error(e);
    setIsLoading(false);
    setBlockLoading(false);
    setTimeout(() => {
      props.history.push("/genericerror");
    }, 1000);
  }
  };


  const onChange = (e: any) => {
    if (EditState === true) {
      dispatch(userChosenCovidQuestion(""));
      dispatch(userChosenContactDetails(""));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      dispatch(isEditState(false));
    }
    if (format.test(e.target.value)) {
      return true;
    } else {
      e.preventDefault();
    }
    setTextValue(e.target.value);
    dispatch(userChosenVulnerabilityAdditionalInformation(e.target.value));
    dispatch(
      updateDashboardForm({
        textArea: e.target.value,
        selected: radiovalue,
      })
    );
  };

  const backButtonHandler = () => {
    dispatch(userChosenVulnerabilityAdditionalInformation(""));
    dispatch(userChosenVulnerabilityQuestion("No"));

    if (eligibilityResponseObject?.statuses.isInDefects === true && vandalismConfigValue === true) {
      props.history.push("/vandalismquestion");
    }
    else {
      if (featureMedia === "true") {
        props.history.push("/uploadmedia");
      } else {
        props.history.push("/repairadditionaldetails");
      }
    }
  };

  const exitButtonHandler = () => {
    props.history.push("/customer360View");
  };

  const saveDraftAndExit = () => {
    setError("none");
    submitForm();
    setCloseAfterSaving(true);
    props.history.replace("/customer360View");
  };

  // update work order
  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }
  const userChosenVandalismQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenVandalismAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );
  const submitForm = async () => {
    try {
      setIsLoading(true);
      setButtonDisable(true);

      workOrderObject.priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      if (radiovalue == "Yes") {
        woRequestParameters.isVulnerable = true;
        woRequestParameters.VulnerableResponseText = textvalue.trim().length > 0 ? textvalue : null;
      } else {
        woRequestParameters.isVulnerable = false;
        woRequestParameters.VulnerableResponseText = null;
      }

      if (userChosenVandalismQuestion == "Yes") {
        woRequestParameters.crimeReferenceNumber =
          userChosenVandalismAdditionalInformation;
        woRequestParameters.repairCause = "Vandalism or Damage";
        woRequestParameters.repairCauseId =
          RepairCauseId[
          woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        woRequestParameters.crimeReferenceNumber = "";
        woRequestParameters.repairCause = "General Wear and Tear";
        woRequestParameters.repairCauseId =
          RepairCauseId[
          woRequestParameters.repairCause as keyof typeof RepairCauseId
          ];
      }

      const apiResponse = await api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      dispatch(workOrderRepairObject(workOrderObject));
    } catch (e) {
      console.error(e);
      setIsLoading(false);
      setTimeout(() => {
        props.history.push("/genericerror");
      }, 1000);
    } finally {
      setIsLoading(false);
      setButtonDisable(false);
    }
  };

  const callPageHandler = () => {
    if (resumeDraftRepairVal) {
      const { vulnerabilityQuestion, vulnerableResponseText } = userResumeDraftDataCache;
      if (vulnerabilityQuestion !== radiovalue || vulnerableResponseText) {
        dispatch(resumeDraftRepair(false));
        dispatch(userChosenCovidQuestion(""));
        dispatch(userChosenContactDetails(""));
        dispatch(userChosenContactPhone(""));
        dispatch(userChosenContactName(""));
      }
    }

    if (textvalue != "" || radiovalue === "No" || (radiovalue == "Yes" && textvalue?.trimEnd() != "")) {
      setError("none");
      submitForm();
      const timerId = setTimeout(() => {
        props.history.push("/covidquestion");
      }, 1000);
    } else {
      setError("block");
    }
  };

  return (
    <>
      <HeaderJourney></HeaderJourney>
      <div className="container-fluid parent-footer repair-request p-0" id="velQues-container">
        <div
          className="padding-repair"
        >
          <h1 className="header-size" id="velQues-main-div">
            {t("Vulnerability")}
          </h1>
          <div id="repAddDet-div6"
            style={{ display: isError }} role="alert"
            placeholder="alert">
          <Message className="info-msg">
          {t("SSR056")}
            </Message>
            </div>
          <div id="velQues-div1">
            <RadioGroup
              id="velQues-radiogroup"
              key={radiovalue}
              onChange={updateSelection}
              defaultValue={radiovalue}
              value={radiovalue}
            >
              <FormControlLabel
                id={"velQues-label1" + 1}
                value="Yes"
                control={
                  <Radio id="velQues-radio3" key="radioYes" />
                }
                label="Yes"
                placeholder="Yes"
              />
              {blockLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) :radiovalue === "Yes" && (
                <> 
                  {BlockMsg==false ?
                   <Message id="velQues-span2" severity="error" className="warning-msg">
                  {t("OrbisBlockMsg1")} <br/>
                   {t("OrbisBlockMsg2")}  <strong className="font-bolder">{t("OrbisBlockNumber")}</strong>  {t("OrbisBlockMsg3")} 
                  </Message> :
                     <div id="velQues-span1" className="vulner-textarea mb-30">
                     <Message className="info-msg">
                     {t("SS039")}
                    </Message>
                       <textarea
                       id="velQues-textarea"
                       className="mt-30 text-area"
                       data-testid="textareaVulnerability"
                       placeholder={t("Vulnerability_Additional_Info")}
                       value={textvalue ?? ""}
                       onChange={(e) => onChange(e)}
                     />
                     </div>
                   }    
                </>
              )}
              <FormControlLabel
                id="velQues-label1"
                value="No"
                control={<Radio id="velQues-radio1" key="radioNo" />}
                label="No"
                placeholder="No"
              />
            </RadioGroup>
          </div>
          
        </div>
        <div className="col-md-12 marginTop-auto" id="velQues-div2">
          <div className="row m-0 footer-actionbtn">
            <div className="col btn-top padding-repair mb-0">
              <BackModal id="velQues-backmodal" back={backButtonHandler} />
              <ExitModalAndSave
                id="velQues-exitmodal"
                exit={exitButtonHandler}
                saveDraftAndExit={saveDraftAndExit}
                closeAfterSaving={closeAfterSaving}
              />
            </div>
            <div className="col text-end pt-24 padding-repair">
              <Button
                id="btn_vulnerability"
                disabled={buttondisable}
                onClick={callPageHandler}
                className="primary-btn"
              >
                {t("continuen_button")}{" "}
              </Button>
            </div>
          </div>
        </div>
        <span>
          {isLoading ? (
            <Loader
              type="ThreeDots"
              color="#00BFFF"
              height={70}
              width={50}
              timeout={5000}
            />
          ) : (
            <>
              <div></div>
            </>
          )}
        </span>
      </div>
    </>
  );
};
export default VulnerabilityQs;