import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "../CustomerDetails/index.scss";
import Loader from "react-loader-spinner";
import {
  userChosenPropertyId,
  userChosenPropertyAddress,
  userChosenRepairLocationId,
  userChosenRepairLocation,
  userChosenHighLevelDescription,
  userChosenSupplementaryQuestions,
  userChosenEmergencyQuestion,
  userChosenAdditionalDescription,
  userChosenCovidQuestion,
  userChosenContactDetails,
  userChosenUPRN,
  userChosenSOR,
  userChosenSTATUS,
  userChosenEligibilityErrorCode,
  userChosenContactPhone,
  userChosenContactName,
  userChosenRelationDetails
} from "../../ducks/redux/actions/userChoices";

import {
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Button from "../../storybook/Button";
import BackModal from "../Modal/BackModal";
import ExitModalAndSave from "../Modal/ExitModalAndSave";
import { WorksOrderRequest } from "../../models/WorksOrders/WorksOrderRequest";
import { workOrderRepairObject } from "../../ducks/redux/actions/getAddress";
import { CustomerName } from "../../models/Customer";
import * as api from "../../api";
import {
  RepairReporterId,
  ReportContactDetails,
} from "../../models/Repair/Repair";
import TextInputField from "../../storybook/TextInputField/TextInputField";
import PageHeader from "../../storybook/PageHeader/PageHeader";
import Dropdown from "../../storybook/Dropdown";
import HeaderJourney from "../../storybook/Header/HeaderJourney"
import Message from "../../storybook/Message";
import { Helper } from "../../utils/Helper/HelperUtil";

interface CustomerDetailsProps extends RouteComponentProps<any> {
  /* cId: any, 
    setErrorCheck: any */
}
const CustomerDetails: React.FC<CustomerDetailsProps> = (props) => {

  enum RepairCauseId {
    "General Wear and Tear" = 1,
    "Vandalism or Damage" = 4,
  }

  const { t } = useTranslation();

  const contactDetailsOptions = [
    { id: 'custDetails-op1', label: t("Customer_Option") },
    { id: 'custDetails-opt2', label: t("Friend_Option") },
    { id: 'custDetails-opt3', label: t("Other_Option") },
  ];

  const customerPhone = useSelector(
    (state: any) => state?.addresses?.customerPhoneNumber
  );

  const priority = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.selectedRepairReasonObject[0]
        ?.priority
  );
  const [inputState, setInputState] = useState<any>(t("Customer_Option"));
  
  const [closeAfterSaving, setCloseAfterSaving] = useState(false);
  const dispatch = useDispatch();
  const [userInput, setUserInput] = useState("");
  var errorArray: any[] = [];
  const [errorArrayState, seterrorArrayState] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [numberInput, setNumberInput] = useState("");
  const radioText = t("Provide_Number");
  const [radiovalue, setRadioValue] = useState(
    customerPhone !== null && customerPhone !== undefined && customerPhone !== ""
      ? customerPhone[0]?.number
        ? `${customerPhone[0].number}@0`
        : radioText
      : radioText
  );
  
  //const isinputdisabled = radiovalue != radioText;
  const [isinputdisabled, setIsInputDisabled] = useState<boolean>(true);
  const [checkRadio, setCheckRadio] = useState<boolean>(true);
  const userChosenContactDetailsBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactDetails
  );
  const userChosenContactNameBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactName
  );
  const userChosenContactPhoneBackButton = useSelector(
    (state: any) => state.userChoicesReducer.userChosenContactPhone
  );
  const userChosenRelationSelect = useSelector(
    (state: any) => state.userChoicesReducer.userChosenRelationDetails
  );
  const userChosenPropertyType = useSelector(
    (state: any) => state.userChoicesReducer.userChosenPropertyType
  );

  const userChosenVulnerabilityQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVulnerabilityQuestion
  );
  const userChosenVulnerabilityAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVulnerabilityAdditionalInformation
  );

  const userChosenVandalismQuestion = useSelector(
    (state: any) => state.userChoicesReducer.userChosenVandalismQuestion
  );
  const userChosenVandalismAdditionalInformation = useSelector(
    (state: any) =>
      state.userChoicesReducer.userChosenVandalismAdditionalInformation
  );
  const workOrderObject = useSelector(
    (state: any) => state.addresses.workOrderRepair
  );

  const covidConfigValue = useSelector(
    (state: any) => state?.addresses?.covidtoggleValue
  );
  const eligibilityResponseObject = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );
  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );

  const priorityCodeValue = useSelector(
    (state: any) => state.userChoicesReducer?.repairPriority
  );


  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.history.action === "PUSH" || props.history.action === "POP" || props.history.action === "REPLACE") {
      if (userChosenContactDetailsBackButton) {
        if (userChosenContactDetailsBackButton.includes('@')) {
          setRadioValue(userChosenContactDetailsBackButton);
        }
        else {
          setRadioValue(userChosenContactDetailsBackButton);

          customerPhone?.map((number: any, index: number) => {
            if (number.number === userChosenContactDetailsBackButton) {
              setRadioValue(userChosenContactDetailsBackButton + '@' + index)
            }
          })
        }
        setIsInputDisabled(radiovalue !== radioText);
      }
      else{
        setIsInputDisabled(
          (checkRadio && customerPhone && customerPhone.length > 0) 
            ? true 
            : radiovalue !== radioText  );
      }

    }
    if (userChosenContactDetailsBackButton == radioText) {
      setRadioValue(radioText);
      setUserInput(userChosenContactNameBackButton);
      setNumberInput(userChosenContactPhoneBackButton);
      if (userChosenRelationSelect === "Friend or family") {
        dispatch(userChosenRelationDetails("Friend or Family"));
      } else {
        dispatch(userChosenRelationDetails(userChosenRelationSelect));
      }
    }
    else if (
      !userChosenContactDetailsBackButton &&
      !userChosenContactPhoneBackButton
    ) {
      if (customerPhone?.length)
        dispatch(userChosenContactDetails(`${customerPhone[0]?.number}@0`));
      dispatch(userChosenRelationDetails(""));
    }
    else {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
    }
   if(customerPhone  && customerPhone.length === 0){
    setCheckRadio(false)
   } 
  }, [radiovalue, customerPhone, radiovalue]);
  
  const updateSelection = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== radioText) {
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactName(""));
      dispatch(userChosenRelationDetails(""));
      setNumberInput("");
      setUserInput("");
      seterrorArrayState([]);
      errorArray = [];
      setIsInputDisabled(true);
    } else {
      dispatch(userChosenRelationDetails("Customer"));
      setCheckRadio(false);
      setIsInputDisabled(false);
    }
    setRadioValue(e.target.value);
    dispatch(userChosenContactDetails(e.target.value));
  };


  const inputChangeHandler = (event: any) => {
    const re = Helper.ValidationRegex.NumericOnly;
    // if value is not blank, then test the regex
    seterrorArrayState([]);
    errorArray = [];
    if (event.target.value === "" || re.test(event.target.value)) {
      //  @ts-ignore
      setNumberInput(event.target.value.toString().slice(0, 11));
      dispatch(userChosenContactPhone(event.target.value.toString().slice(0, 11)));
    }
  };

  const nameInputChangeHandler = (e: any) => {
    const re = Helper.ValidationRegex.AlphabetWithSpecialChars;
    // if value is not blank, then test the regex
    if (e.target.value === "" || re.test(e.target.value)) {
      setUserInput(e.target.value);
      dispatch(userChosenContactName(e.target.value));
    }
  };

  const submitForm = (): boolean => {
    seterrorArrayState([]);
    errorArray = [];

    const phoneEmpty = t("SSR014");
    const phoneInvalid = t("SSR002");
    const nameError = t("SSR058");
    const ukCountryCode = "0";

    if (radiovalue === radioText) {
      if (!numberInput) {
        errorArray.push(phoneEmpty);
      } else if (
        numberInput.slice(0, 1) !== ukCountryCode ||
        numberInput.length < 11
      ) {
        errorArray.push(phoneInvalid);
      }
      if (!userInput || userInput.length <= 0) {
        errorArray.push(nameError);
      }
    }

    if (errorArray.length > 0) {
      seterrorArrayState([...errorArray]);
      return false;
    }

    try {
      setIsLoading(true);
      dispatch(userChosenRelationDetails(inputState));
      workOrderObject.reporterContactDetails = new ReportContactDetails();
      if (radiovalue !== radioText) {
        const radioUpdatedValue = radiovalue.split("@");

        workOrderObject.reporterContactDetails.contactNumber = radioUpdatedValue[0];
      } else {
        workOrderObject.reporterContactDetails.contactNumber =
          numberInput.length > 0 ? numberInput : null;
        dispatch(userChosenRelationDetails(inputState));
        dispatch(userChosenContactPhone(numberInput));
        dispatch(userChosenContactName(userInput));
        dispatch(userChosenContactDetails(radioText));
      }

      const contactPersonName = new CustomerName({
        title: null,
        givenName: radiovalue !== radioText ? " " : userInput?.trim(),
        surname: null,
      });
      workOrderObject.reporterContactDetails.name = 
      contactPersonName;

      workOrderObject.reportedById =
        RepairReporterId[inputState as keyof typeof RepairReporterId];
      workOrderObject.reportedBy = inputState;

      // This will always be set as true in Self Serve channels, verified with ERGO, where 
      // 1. IF Customer contacts, the reporterToBeContacted = true
      // 2. IF someone else contacts and agrees to be contacted,
      //    THEN contact detail has to be submitted, the reporterToBeContacted = true
      // 3. IF someone else contacts and disagrees to be contacted,
      //    THEN contact detail is not submitted, the reporterToBeContacted = false

      workOrderObject.reporterContactDetails.reporterToBeContacted = true;

      userChosenVulnerabilityQuestion !== "" &&
      userChosenVulnerabilityQuestion !== null &&
      userChosenVulnerabilityQuestion !== "No"
        ? (workOrderObject.isVulnerable = true)
        : (workOrderObject.isVulnerable = false);
      userChosenVulnerabilityAdditionalInformation !== ""
        ? (workOrderObject.VulnerableResponseText = userChosenVulnerabilityAdditionalInformation)
        : (workOrderObject.VulnerableResponseText = null);

      if (userChosenVandalismQuestion === "Yes") {
        workOrderObject.crimeReferenceNumber = userChosenVandalismAdditionalInformation;
        workOrderObject.repairCause = "Vandalism or Damage";
        workOrderObject.repairCauseId =
          RepairCauseId[
            workOrderObject.repairCause as keyof typeof RepairCauseId
          ];
      } else {
        workOrderObject.crimeReferenceNumber = "";
        workOrderObject.repairCause = "General Wear and Tear";
        workOrderObject.repairCauseId =
          RepairCauseId[workOrderObject.repairCause as keyof typeof RepairCauseId];
      }
      workOrderObject.priority = priorityCodeValue;
      const _worksOrderRequest = new WorksOrderRequest(workOrderObject);
      const woRequestParameters =
        _worksOrderRequest.updateWorkOrderParameter(workOrderObject);
      api.updateWorkOrderRepair(
        woRequestParameters,
        workOrderObject.id,
        "eTag"
      );
      dispatch(workOrderRepairObject(workOrderObject));
      return true;
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        props.history.push("/genericerror");
      }, 1000);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const saveDraftAndExit = () => {
    seterrorArrayState([]);
    const isValid = submitForm();
    if (isValid) {
      setCloseAfterSaving(true);
      props.history.replace("/customer360View");
    }
  };

  const callPageHandler = () => {
    seterrorArrayState([]);
    const isValid = submitForm();
    if (isValid) {
      props.history.push("/confirmationdetails");
    }
  };

  const backButtonHandler = () => {
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));

    if (userChosenPropertyType !== "Property") {
      if (priority !== "Emergency") {
        props.history.replace("/vulnerabilityquestion");
      } else {
        if (
          eligibilityResponseObject?.statuses.isInDefects === true &&
          vandalismConfigValue === true
        ) {
          props.history.replace("/vandalismquestion");
        } else {
          props.history.push("/uploadmedia");
        }
      }
    } else if (covidConfigValue === "false") {
      if (priority !== "Emergency") {
        props.history.replace("/vulnerabilityquestion");
      } else {
        if (
          eligibilityResponseObject?.statuses.isInDefects === true &&
          vandalismConfigValue === true
        ) {
          props.history.replace("/vandalismquestion");
        } else {
          props.history.push("/uploadmedia");
        }
      }
    } else {
      props.history.replace("/covidquestion");
    }
  };

  const exitButtonHandler = () => {
    dispatch(userChosenPropertyId(""));
    dispatch(userChosenPropertyAddress(""));
    dispatch(userChosenRepairLocationId(0));
    dispatch(userChosenRepairLocation(""));
    dispatch(userChosenHighLevelDescription(""));
    dispatch(userChosenSupplementaryQuestions(""));
    dispatch(userChosenEmergencyQuestion(""));
    dispatch(userChosenAdditionalDescription(""));
    dispatch(userChosenCovidQuestion(""));
    dispatch(userChosenContactDetails(""));
    dispatch(userChosenUPRN(""));
    dispatch(userChosenSOR(""));
    dispatch(userChosenSTATUS(""));
    dispatch(userChosenEligibilityErrorCode(""));
    dispatch(userChosenContactPhone(""));
    dispatch(userChosenContactName(""));
    dispatch(userChosenRelationDetails(""));
    props.history.push("/customer360View");
  };

  const handleChange = (e: { target: { value: any } }) => {
    setInputState(e.target.value);
    dispatch(userChosenRelationDetails(e.target.value));
  };


  let errorfieldsArray: any[] = [];
  errorArrayState?.forEach((err: any) => {
    errorfieldsArray.push(
      <Message className="warning-msg">
        {err}
      </Message>
    );
  });
  return (
    <>
    <HeaderJourney ></HeaderJourney>
    <Container id="custDetails-container" className="padding-repair" role="main">

      <div id="custDetails-main-div">
        <PageHeader>
          {t("Customer_Details")}
        </PageHeader>
        {errorfieldsArray.length > 0 && (
          <div
            id="custDetails-div5"
            role="alert"
            placeholder="alert"
            className="detail-msg">
            {errorfieldsArray}
          </div>
        )}
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={50}
            width={50}
            timeout={5000}
          />
        ) : (
          <>
            <div
              className="pt-44"
              id="options"
              data-hj-suppress="">
              <RadioGroup
                id="custDetails-radiogroup"
                
                onChange={updateSelection}
                value={radiovalue}
              >
                {customerPhone.map((number: any, index: number) => {
                  return (
                    number.number && (
                      <FormControlLabel
                        id="custDetails-label1"
                        key={"customerPhoneNumber_" + index}
                        control={
                          <Radio
                            id="custDetails-radio1"
                            key="checkboxPhoneNumber"
                            name="checkboxPhoneNumber"
                            data-testid="checkboxPhoneNumber"
                          />
                        }
                        label={number.number}
                        value={`${number.number}@${index}`}
                      />
                    )
                  );
                })}
                <FormControlLabel
                  id="custDetails-label2"
                  control={
                    <Radio
                      id="custDetails-radio2"
                      key="phoneNumberforRepair"
                      name="phoneNumberforRepair"
                      data-testid="phoneNumberforRepair"
                    />
                  }
                  label={radioText}
                  value={radioText}
                />{" "}
              </RadioGroup>
            </div>

            <div id="custDetails-div1" className="parent-div">
              <div
                id="custDetails-div2"
                className="pt-2 pt-lg-0"
              >
                <h6 className="pb-12">Phone number</h6>
                <TextInputField
                  id="custDetails-text1"
                  dataTestId="inputPhoneNumber"
                  ariaLabel="phone number"
                  type="text"
                  disabled={isinputdisabled}
                  value={numberInput}
                  placeholder="Please enter your phone number"
                  onChange={(e) => inputChangeHandler(e)}
                >
                </TextInputField>
              </div>

              <div
                id="custDetails-div3"
                className="pt-24"
              >
                <h6 className="pb-12">Name</h6>
                <TextInputField
                  id="custDetails-text3"
                  dataTestId="customerName"
                  ariaLabel="customer name"
                  type="text"
                  disabled={isinputdisabled}
                  value={userInput}
                  placeholder="Name"
                  onChange={nameInputChangeHandler}
                >
                </TextInputField>

              </div>

              <div
                id="custDetails-div4"
                className="pt-24"
              >
                <h6 className="pb-12">{t("Repair_Contact_Details_Selection_Query")}</h6>
                <Dropdown
                  options={contactDetailsOptions}
                  selectedOption={userChosenRelationSelect ?? inputState}
                  disabled={isinputdisabled}
                  handleChange={handleChange}
                  dataTestId="inputState"
                >
                </Dropdown>


              </div>
            </div>
          </>
        )}
        
      </div>
    </Container>
    <div className="col-md-12 marginTop-auto">
        <div className="row m-0 footer-actionbtn">
          <div className="col btn-top padding-repair mb-0">

            <BackModal id="custDetails-backmodal" back={backButtonHandler} />

            <ExitModalAndSave
              id="custDetails-exitmodal"
              exit={exitButtonHandler}
              saveDraftAndExit={saveDraftAndExit}
              closeAfterSaving={closeAfterSaving}
            />
          </div>
          <div className="col text-end pt-24 padding-repair">
            <Button
              id="btn_custmerDetails"
              onClick={callPageHandler}
              className="primary-btn"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>    
    </>
  );
};

export default CustomerDetails;
