import React, { useState, useEffect, useRef, useContext } from "react";
import * as api from "../../api";
import { CurrentRepair, RepairHistory, RepairStatusField } from "../../models/Repair/Repair";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import CancelModelRepair from "../Modal/CancelModelRepair";
import { clearMediaFiles, isMediaResume, resumeDraftRepair, selectedWorkorderForMedia, showMenuTabs, showFooter, userChosenAdditionalDescription, userChosenAdditionalDescriptionCOUNT, userChosenContactDetails, userChosenContactName, userChosenContactPhone, userChosenCovidQuestion, userChosenEmergencyQuestion, userChosenHighLevelDescription, userChosenIsReporter, userChosenPropertyAddress, userChosenPropertyId, userChosenPropertyType, userChosenRelationDetails, userChosenRepairLocation, userChosenRepairLocationId, userChosenSOR, userChosenSupplementaryQuestions, userChosenUPRN, userChosenVandalismAdditionalInformation, userChosenVandalismQuestion, userChosenVulnerabilityAdditionalInformation, userChosenVulnerabilityQuestion, userCreatedNewRepair, userResumeData, userResumeDraftDataCache, repairPriorityValue } from "../../ducks/redux/actions/userChoices";
import { RepairPriority, ContractorRepairPriority } from "../../models/Repair/CreateRepair";
import { FeatureFlags } from "../contexts/FeatureFlags";
import { getMaintResp, getRepairResp, saveCustomerDetails, saveCustomerphoneNumber, selectedTenacyType, workOrderRepairObject, getDeterminedContractorDetails, getContractorPayload, saveSupplimentaryCodes } from "../../ducks/redux/actions/getAddress";
import { isNullOrUndefined } from "../../utils/formatting";
import { trackException } from "../../appInsights/TelemetryService";
import { eligibilityResponseObject, resumeRepairReasonPriorityUpdatedToEmergency, selectedRepairReasonPriorityDetermination } from "../../ducks/redux/actions/dashboardReducer.actions";
import { repairHistoryData } from "../../ducks/redux/actions/historicRepair";
import Button from "../../storybook/Button";
import Message from "../../storybook/Message";
import Chevronleft from "../../assets/img/Chevronleft.png";
import mobileChevronleft from "../../assets/img/mobileChevronleft.png";
import MediaList from "../MediaList/index"
import ReScheduleModelRepair from "../Modal/ReScheduleModelRepair";
import ListingDetailHeader from "../../storybook/Header/ListingDetailHeader";
import { RepairChannel } from "../../models/Repair/RepairChannel";

interface RepairHistoryDetailsProps extends RouteComponentProps<any> {
  Appointment?: string;
  Priority?: string;
  Contractor?: string;
  Description?: string;
  workOrderID?: string;
  status?: string;
  repairReference?: string;
  area?: string;
  fullDescription?: string;
  appointmentDate?: string;
  ContractorsList?: string[];
  siteIdentifier?: string;
  associtaedAsset?: string;
}

const RepairHistoryDetails: React.FC<RepairHistoryDetailsProps> = (props) => {
  let moment = require("moment");
  const dispatch = useDispatch();
  let contractorSiteIdentifer = "";

  //#region  "page state"


  const [repairHistoryDetails, setRepairHistoryDetails] = React.useState<
    RepairHistory[]
  >([]);


  const [mostRecentStatus, setMostRecentStatus] = useState("");
  const [mostRecentStatusClass, setMostRecentStatusClass] = useState("");
  const [mostRecentStatusMobileClass, setmostRecentStatusMobileClass] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const [isAppointmentAvailable, setIsAppointmentAvailable] = useState("none");
  const [isCancelButton, setIsCancelButton] = useState("none");
  const [isRescheduleButton, setIsRescheduleButton] = useState("none");
  const [isRescheduleShow, setIsRescheduleShow] = useState("none");
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [errorCodeReschedule, setErrorCodeReschedule] = React.useState("");
  const [contractorContactNumber, setContractorContactNumber] = useState("");
  const { featureMedia }: any = useContext(FeatureFlags);
  const [rescheduleMessage, setRescheduleMessage] = useState("");
  const [draftRepair, setDraftRepair] = useState<any>(); 
  const [contractorSitesIdentifierValue, setContractorSitesIdentifierValue] = useState("");
  const [isMedia, setIsMedia] = useState(false);  
  const [communalToggle, setCommunalToggle] = useState("true");
  const customerID = useSelector((state: any) => state.addresses?.customerid);
  const [mediaOwner, setMediaOwner] = useState(customerID);
  const _customerId = localStorage.getItem("customerId") || ""; 
  const [isActive, setIsActive] = React.useState(false);
  const [isReScheduleModal, setIsReScheduleModalShow] = useState(false);

  const [appointmentStartDate, setAppointmentStartDate] = useState("");
  const [appointmentEndDate, setAppointmentEndDate] = useState("");
  const [isSelectAppointmentChosen, setIsSelectAppointmentChosen] = useState(false);
  const [woHadDemobilizedContractorSite, setWOHadDemobilizedContractorSite] = useState(false);
  const [contractorDetails, setContractorDetails] = useState<any>(null);
 
  let isIntegrated = false; 

  //#endregion "state"

  //#region "selectors"

  const isComesFromMediaPage = useSelector(
    (state: any) => state?.userChoicesReducer?.isFromMediaPage
  );
  const userRepairWorkOrderValue = useSelector(
    (state: any) => state.userChoicesReducer?.userRepairWorkOrder
  );  

  const repairHistoryDataValue = useSelector(
    (state: any) => state.historicRepairReducer?.repairHistoryData
  );

  const contractorphone = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenContactPhone
  );
  const contractorname = useSelector(
    (state: any) => state.userChoicesReducer?.userChosenContactName
  );

 
  let eligibilityResponseObjectFromState = useSelector(
    (state: any) =>
      state?.dashboardReducer?.formState?.eligibilityResponseObject
  );

  const vandalismConfigValue = useSelector(
    (state: any) => state?.addresses?.vandalismtoggleValue
  );

  const covidConfigValue = useSelector(
    (state: any) => state?.addresses?.covidtoggleValue
  );

  const isCatalyst = useSelector(
    (state: any) => state.userChoicesReducer?.isCatalystCustomer
  );

  const getContractorInfoUsingConsite = (repairHistoryData: any, contractorSiteIdentifier: any) => {
    const filteredContractorMatch = repairHistoryData?.RepairHistoryContractorsList?.filter((contractor: any) =>
      contractor?.contractorSites?.includes(contractorSiteIdentifier)
    ) || [];

    return filteredContractorMatch?.length > 0 ? filteredContractorMatch[0] : null;
  };

  const demobilizedContractorList = useSelector(
    (state: any) => state?.userChoicesReducer?.demobilizedContractorSites
  );

  //#endregion "selectors"

 
  //#region "functions" 

  const backButtonHandler = () => {
    dispatch(showMenuTabs(true));
    props.history.goBack();
  };

  const selectAppointmentHandler = () => {
    setIsSelectAppointmentChosen(true);
    if(!woHadDemobilizedContractorSite) {
    props.history.push("/appointmentscheduler");
    }
  };

  const callPageHandler = (workOrderId: string | undefined) => {
    (async () => {
      try {
        setIsLoading(true);
        setIsActive(!isActive);
        if (isActive === false) {
          if (workOrderId != undefined) {
            const workOrderResponse = await api.getWorkOrder(workOrderId);
            setWOHadDemobilizedContractorSite(demobilizedContractorList?.some((x: string) => x.toLowerCase() === workOrderResponse?.contractor?.toLowerCase()));
            setDraftRepair(workOrderResponse);
            setMediaOwner(workOrderResponse?.customerIdentifier);
            // === Adding view media button condition ===	

            if (workOrderResponse?.documents?.documentIdentifiers?.length > 0) {             
              setIsMedia(true);
            } else {
              setIsMedia(false);
            }
            // === end ===	
            const _customerId = localStorage.getItem("customerId") || "";
            if (workOrderResponse != null) {
              contractorSiteIdentifer = workOrderResponse?.contractor || "";
              
              if (contractorSiteIdentifer) {
                const contractorDetails = getContractorInfoUsingConsite(repairHistoryDataValue, contractorSiteIdentifer);
                if(isNullOrUndefined(contractorDetails) && draftRepair?.contractorName) {
                  contractorDetails.name = draftRepair?.contractorName
                }
                setContractorDetails(contractorDetails);
                dispatch(getDeterminedContractorDetails(contractorDetails));
                setContractorContactNumber(contractorDetails?.contacts[0]?.addresses?.digitalAddresses[0]?.telecomsAddresses[0]?.number ?? draftRepair?.contractorPhoneNumber);
                setContractorSitesIdentifierValue(contractorSiteIdentifer);
                setAppointmentStartDate(workOrderResponse.appointmentStartDate);
                setAppointmentEndDate(workOrderResponse.appointmentEndDate);
                isIntegrated = contractorDetails?.statuses?.isIntegrated;

                //get reschedule eligibility

                if (
                  isIntegrated &&
                  workOrderResponse.area === "Property" &&
                  workOrderResponse.priority === RepairPriority.NextAvailable &&
                  workOrderResponse.status === RepairStatusField.Unappointed
                ) {
                  setIsAppointmentAvailable("inline");
                  setIsRescheduleButton("none");
                  return;
                }
                else {
                  reschedule(workOrderResponse, _customerId, isIntegrated, contractorDetails);
                  setIsAppointmentAvailable("none");
                  return;
                } 
              }

              //get cancel repair eligibility
              const eligibilityResponse = await api.getCancelRepairEligibility(
                (workOrderResponse.reportingMethod = "CustomerSelfService"),
                workOrderResponse.status,
                workOrderResponse.isDraft,
                workOrderResponse.isEmergency,
                workOrderResponse.isOutOfHours,
                workOrderResponse.isRecall != undefined
                  ? workOrderResponse.isRecall
                  : false,
                workOrderResponse.isEMA,
                workOrderResponse.isPotentialDuplicate,
                workOrderResponse.contractor,
                workOrderResponse.repairLocation,
                workOrderResponse.trade,
                workOrderResponse.sorCode,
                moment(new Date(workOrderResponse.raisedDate)).format(
                  "yyyy-MM-DD"
                ),
                repairHistoryDataValue.RepairHistoryarea || "",
                workOrderResponse?.appointmentStartDate,
                _customerId,
                workOrderResponse?.customerIdentifier
              );
              if (eligibilityResponse.isEligible) {
                setIsCancelButton("inline");
                if (eligibilityResponse.errorCode === "") {
                  setErrorCode("SSR036");
                  setIsCancelButton("inline");
                } else {
                  setIsCancelButton("none");
                  setErrorCode(eligibilityResponse.errorCode);
                  setCancelMessages(eligibilityResponse.errorCode)
                }
                // setIsEligible(eligibilityResponse.isEligible);
              } else {
                setErrorCode("SSR036");
                setIsCancelButton("none");
              }
            }
          }
        }

        setIsLoading(true);

        //Scroll to top of current repair detail parent element if returning to Historic Repairs Details Page
        if (!isNullOrUndefined(repairHistoryDataValue?.repairHistoryOperation)) {
          const isMobileView = document.getElementById('scrollableDiv')?.offsetParent != null,
            parentHTMLBody = isMobileView ? document.getElementById('scrollableDiv') : document.getElementById('paginationDiv'),
            repairDivIdQuery = "#Repair_Id_" + workOrderId,
            repairDiv = parentHTMLBody?.querySelector(repairDivIdQuery) as HTMLElement | null;
          repairDiv?.scrollIntoView();
          dispatch(repairHistoryData(""));
        }

      } catch (e: any) {
        setIsLoading(true);
        const _channel = process.env.REACT_APP_X_CHANNEL_IDENTIFIER;
        const _customerId = localStorage.getItem("customerId") || "";
        const _corelationId = localStorage.getItem("corelationId") || "";
        trackException(e, _channel, _customerId, _corelationId);
        console.error(e);
        props.history.push("/genericerror");
      }
    })();
  };

  const reschedule = async (
    workOrderResponse: CurrentRepair,
    _customerId: string,
    isIntegrated: boolean,
    contractorData: any
  ) => {
    const eligibilityResponse = await api.getRescheduleEligibility(
      (workOrderResponse.reportingMethod = "CustomerSelfService"),
      workOrderResponse.id,
      workOrderResponse.status,
      workOrderResponse.isDraft,
      workOrderResponse.isEmergency,
      workOrderResponse.isOutOfHours,
      workOrderResponse.isRecall != undefined
        ? workOrderResponse.isRecall
        : false,
      workOrderResponse.isEMA,
      workOrderResponse.isPotentialDuplicate,
      workOrderResponse.contractor,
      workOrderResponse.repairLocation,
      workOrderResponse.trade,
      workOrderResponse.sorCode,
      moment(new Date(workOrderResponse.raisedDate)).format("yyyy-MM-DD"),
      repairHistoryDataValue.RepairHistoryarea || "",
      workOrderResponse?.appointmentStartDate != undefined
        ? moment(new Date(workOrderResponse?.appointmentStartDate)).format(
          "yyyy-MM-DD"
        )
        : "",

      _customerId,
      workOrderResponse?.customerIdentifier,
      isIntegrated,
      workOrderResponse?.lastAppointmentCreatedDate != undefined
        ? moment(workOrderResponse?.lastAppointmentCreatedDate).format(
          "yyyy-MM-DD"
        )
        : "",
    );

    if (workOrderResponse.status !== RepairStatusField.Appointed) {
      setIsRescheduleButton("none");
    }
    else if (eligibilityResponse.isEligible ) {
      
      setErrorCodeReschedule(eligibilityResponse.errorCode);
      //setAppointmentStartDate(workOrderResponse.appointmentStartDate);
      //setAppointmentEndDate(workOrderResponse.appointmentEndDate);
      if (eligibilityResponse.errorCode.toString() === "SSR071" || eligibilityResponse.errorCode.toString() === "SSR075") {
        let message = t(eligibilityResponse.errorCode);        
        message = message.replace("{0}", contractorData.name);
        message = message.replace("{1}", contractorData.contacts[0]?.addresses?.digitalAddresses[0]
        ?.telecomsAddresses[0]?.number);
        setRescheduleMessage(message);
        setIsRescheduleButton("none");
        setIsRescheduleShow("inline")
      }
      else if (eligibilityResponse.errorCode.toString() === "SSR070") {
        const message = t("SSR070");
        setRescheduleMessage(message);
        setIsRescheduleButton("none");
        setIsRescheduleShow("inline")
      }
      else {
        if (isNullOrUndefined(eligibilityResponse.errorCode)) {
          setIsRescheduleShow("none");
          setIsRescheduleButton("inline");
          return;
        }
      }      
    } else {
      setIsRescheduleButton("none");
    }

  };
  const resumeRepairHandler = async (wid: any) => {
    dispatch(showMenuTabs(false));
    dispatch(showFooter(false));
    dispatch(resumeDraftRepair(true));
    dispatch(clearMediaFiles([]));
    dispatch(isMediaResume(true));
    dispatch(userChosenPropertyAddress(repairHistoryDataValue.RepairHistoryassocitaedAsset));

    let assetrResponse = null;
    const customerResponse = await api.getCustomer(draftRepair.customerIdentifier);
    dispatch(saveCustomerDetails(customerResponse));
    dispatch(
      saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses)
    );
    dispatch(
      saveCustomerphoneNumber(customerResponse?.addresses?.telecomsAddresses))
    dispatch(userChosenIsReporter(draftRepair.reporterToBeContacted));
    if (draftRepair.reporterContactDetails != null) {
      const name = draftRepair.reporterContactDetails?.name?.trim();
      dispatch(userChosenContactPhone(draftRepair.reporterContactDetails.contactNumber))
      dispatch(userChosenContactName(name))
      dispatch(userChosenRelationDetails(draftRepair.reportedBy));
      dispatch(userChosenContactDetails("I would like to provide number for this repair."));

      draftRepair.reporterContactDetails.name = null;
    }
    else {
      //dispatch(userChosenContactDetails(draftRepair.co));
      dispatch(userChosenRelationDetails("Customer"));
      dispatch(userChosenContactPhone(""));
      dispatch(userChosenContactDetails(customerResponse?.addresses?.telecomsAddresses?.[0].number));
      dispatch(
        userChosenContactDetails(
          customerResponse?.addresses?.telecomsAddresses?.[0].number
        )
      );

    }

    /*** This fetches the High Level, Supplementary Question and Repair Emergency Data ***/
    const area = repairHistoryDataValue?.RepairHistoryarea != undefined ? repairHistoryDataValue?.RepairHistoryarea : "";
    const premiseType =
      area === "Block" ||
        area === "Estate" ||
        area === "Divided block"
        ? "Communal"
        : area;
    const repairReasonResponsedata = await api.searchRepairReasons(draftRepair.repairReason, premiseType,isCatalyst);

    let selectedReason = repairReasonResponsedata.filter(
      (reason: any) => reason.description === draftRepair.description
    );
  
    const isContractorEMA = draftRepair.isEMA ? "Y" : "N"
      const raisedFormattedDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");

      //to keep up the sorcode change for total loss of water
      const responseTenancyGroup = await api.getTenanciesGroup(draftRepair.customerIdentifier, draftRepair.premisesId);
      const tenancyGroup = responseTenancyGroup?.[0]?.tenancyGroup || "";
      const responsePremisesEligibility = await api.postPremisesEligibility(
          draftRepair.premisesId,
          tenancyGroup
      );
      const supplementaryCodes = responsePremisesEligibility?.[0]?.supplimentaryCodes || "";
      dispatch(saveSupplimentaryCodes(supplementaryCodes));

      const isSupplementaryCode = selectedReason[0]?.description === "Total loss of water" && supplementaryCodes?.includes("FISPUMPS02");
      const sorCodeUpdated = isSupplementaryCode ? "COMPUMP" : draftRepair.originalWorkOrderSorCode
      draftRepair.sorCode = sorCodeUpdated
      draftRepair.originalWorkOrderSorCode = sorCodeUpdated

    /*** This fetches the Updated Seasonal Priority for mapped Repair reasons from Drools ***/
    const priorityDeterminationResponse = (await api.postRepairPriority({
              repairType: draftRepair.sorCode,
              isEmergency: draftRepair.isEmergency,
              isRecall: draftRepair.isRecall,
              isEMA: isContractorEMA,
              isVulnerable: draftRepair.isVulnerable,
              description: draftRepair.description,
              raisedDate: raisedFormattedDate,
              channel: RepairChannel.CustomerSelfService
    }))?.repairPriority;

    dispatch(repairPriorityValue(priorityDeterminationResponse));

    const priorityValue = 
    priorityDeterminationResponse === ContractorRepairPriority.Emergency ? RepairPriority.Emergency
      : (priorityDeterminationResponse === ContractorRepairPriority.NextAvailable
        || priorityDeterminationResponse == ContractorRepairPriority.DampAndMould
      ) ? RepairPriority.NextAvailable : null;
   
    const repairReasonPriorityIsUpdated = priorityValue != null && priorityValue !== draftRepair.priority;
    draftRepair.priority = repairReasonPriorityIsUpdated ? priorityValue : draftRepair.priority;
    draftRepair.isEmergency = draftRepair.priority == "Emergency" || draftRepair.priority == "Emergency (OOH)" ? true : false;
    dispatch(selectedRepairReasonPriorityDetermination(draftRepair.priority));
    dispatch(resumeRepairReasonPriorityUpdatedToEmergency(repairReasonPriorityIsUpdated && draftRepair.isEmergency));
    draftRepair.isDraft = null;

    dispatch(workOrderRepairObject(draftRepair));
    dispatch(userCreatedNewRepair(draftRepair));
    const {
      id,
      premisesId,
      repairLocationId,
      repairLocation,
      repairReason,
      isSupplementary,
      isEmergency,
      isPotentialDuplicate,
      additionalInformation,
      isVandalism,
      repairCauseId,
      crimeReferenceNumber,
      isVulnerable,
      VulnerableResponseText,
      isCovidSelfIsolated,
      reporterContactDetails,
      premisesAddress,
      premisesUniqueReference,
      originalWorkOrderSorCode,
    } = draftRepair;

    // const area = props?.area != undefined ? props?.area : "";

    if (repairHistoryDataValue.RepairHistoryassocitaedAsset != null) {      
      assetrResponse = await api.getAssetsByid(premisesId);
      eligibilityResponseObjectFromState = assetrResponse;
      dispatch(eligibilityResponseObject(assetrResponse));
      let maintsResp =
        assetrResponse?.maintenanceResponsibilities?.find(
          (resp: any) => resp.type === "MAINTSRESP"
        )?.value ?? "";
      let repairResp =
        assetrResponse?.maintenanceResponsibilities?.find(
          (resp: any) => resp.type === selectedReason[0]?.element
        )?.value ?? "";
      dispatch(userChosenPropertyId(assetrResponse.id));
      dispatch(getMaintResp(maintsResp));
      dispatch(getRepairResp(repairResp));
    }
    if (assetrResponse?.addresses?.postalAddresses[0].formattedAddress != null) {
      dispatch(userChosenPropertyAddress(assetrResponse?.addresses?.postalAddresses[0].formattedAddress));
    }
    
    dispatch(selectedTenacyType(area));
   
    const currWorkOrder = userRepairWorkOrderValue?.models?.filter(
      (eachWorkOrder: any) => {
        return eachWorkOrder.id === id;
      }
    );

    if (
      selectedReason[0]?.additionalData &&
      selectedReason[0]?.additionalData.length &&
      selectedReason[0]?.additionalData[0]?.description != ""
    ) {
      const resumeData = {
        resumeHLRepair: selectedReason[0]?.description,
        resumeHLSubQuestion: selectedReason[0]?.additionalData[0]?.description,
        resumeSelectedProperty: area,
        resumePriority: draftRepair.Priority ?? repairHistoryDataValue.RepairHistoryPriority,
      };
      dispatch(userResumeData(resumeData));
    } else {
      const resumeData = {
        resumeHLRepair: "",
        resumeHLSubQuestion: "",
        resumeSelectedProperty: area,
        resumePriority: draftRepair.Priority ?? repairHistoryDataValue.RepairHistoryPriority,
      };
      dispatch(userResumeData(resumeData));
    }
    dispatch(userChosenPropertyId(premisesId));
    dispatch(userChosenPropertyType(area));
    dispatch(userChosenPropertyAddress(repairReason));
      dispatch(userChosenUPRN(premisesUniqueReference));
      dispatch(userChosenSOR(sorCodeUpdated));

    repairLocation &&
      dispatch(userChosenRepairLocation(repairLocation)) &&
      dispatch(userChosenRepairLocationId(repairLocationId));
    repairReason && dispatch(userChosenHighLevelDescription(repairReason));
    isSupplementary === true
      ? dispatch(userChosenSupplementaryQuestions("Yes"))
      : isSupplementary === false
        ? dispatch(userChosenSupplementaryQuestions("No"))
        : dispatch(userChosenSupplementaryQuestions(""));
    isEmergency &&
      dispatch(userChosenEmergencyQuestion(t("Repair_Emergency_Radio1_Text")));
    additionalInformation &&
      dispatch(userChosenAdditionalDescription(t(additionalInformation))) &&
      dispatch(
        userChosenAdditionalDescriptionCOUNT(additionalInformation.length)
      );
    isVandalism
      ? dispatch(userChosenVandalismQuestion("Yes")) &&
      dispatch(userChosenVandalismAdditionalInformation(crimeReferenceNumber))
      : dispatch(userChosenVandalismQuestion("No"));
    isVulnerable
      ? dispatch(userChosenVulnerabilityQuestion("Yes")) &&
      dispatch(
        userChosenVulnerabilityAdditionalInformation(VulnerableResponseText)
      )
      : dispatch(userChosenVulnerabilityQuestion("No"));
    isCovidSelfIsolated === true
      ? dispatch(userChosenCovidQuestion("Yes"))
      : isCovidSelfIsolated === false
        ? dispatch(userChosenCovidQuestion("No"))
        : dispatch(userChosenCovidQuestion(""));
    const resumeDraftDataCache = {
      repairLocation,
      repairLocationId,
      repairReason,
      supplementaryQuestions:
        isSupplementary === true
          ? "Yes"
          : isSupplementary === false
            ? "No"
            : "",
      emergencyQuestion: isEmergency && t("Repair_Emergency_Radio1_Text"),
      additionalInformation,
      vandalismQuestion: isVandalism ? "Yes" : "No",
      crimeReferenceNumber: isVandalism ? crimeReferenceNumber : "",
      vulnerabilityQuestion: isVulnerable ? "Yes" : "No",

      vulnerableResponseText: isVulnerable ? VulnerableResponseText : "",
      covidQuestion:
        isCovidSelfIsolated === true
          ? "Yes"
          : isCovidSelfIsolated === false
            ? "No"
            : "",
      reporterContactDetails,
    };
    dispatch(userResumeDraftDataCache(resumeDraftDataCache));
  
    if (!repairLocation || !repairReason) {
      props.history.push("/repairlocation");
    }
    else if (repairReasonPriorityIsUpdated === true && draftRepair.isEmergency) {
      props.history.push("/repairemergency");
    }

    // === Validating draft from media page ===	
    else if (isComesFromMediaPage === true && featureMedia === "true") {
      props.history.push("/uploadmedia");
    }
    else if (!additionalInformation && featureMedia === "false") {
      if (isSupplementary === null) {
        props.history.push("/supplementaryquestion");

      } else if (isEmergency !== true && isEmergency !== false) {
        props.history.push("/repairemergency");
      }
      else if (isPotentialDuplicate === true) {
        props.history.push("/repairadditionaldetails");

      } else {
        props.history.push("/duplicaterepair");
      }
    }

    else if (isVandalism === null && (eligibilityResponseObjectFromState?.statuses.isInDefects === true && vandalismConfigValue === true && featureMedia === "false")) {
      props.history.push("/vandalismquestion");
    } else if (isVulnerable === null && featureMedia === "false") {
      props.history.push("/vulnerabilityquestion");
    }
    else if (isVulnerable === true && VulnerableResponseText === null && featureMedia === "false") {
      props.history.push("/vulnerabilityquestion");
    }
    else if (area === "Property" && isCovidSelfIsolated === null && covidConfigValue && featureMedia === "false") {
      props.history.push("/covidquestion");
    }
    else if (reporterContactDetails != null && featureMedia === "false") {

      if (draftRepair.reporterContactDetails?.name?.trim()?.length === 0 || draftRepair.reporterContactDetails?.contactNumber?.trim()?.length === 0) {

        if (isCovidSelfIsolated === null && covidConfigValue) {
          draftRepair.reporterContactDetails.name = null;
          props.history.push("/covidquestion");
        }
        else {
          draftRepair.reporterContactDetails.name = null;
          props.history.push("/customerdetails");
        }
      }
      else {
        props.history.push("/confirmationdetails");
      }
    }
    else if (draftRepair.reportedBy.trim().length <= 0 && featureMedia === "false") {
      props.history.push("/customerdetails");
    }
    else if (featureMedia === "false") {
      if (draftRepair.reporterContactDetails != null) {
        const name = draftRepair.reporterContactDetails?.name?.trim();
        dispatch(
          userChosenContactPhone(
            draftRepair.reporterContactDetails.contactNumber
          )
        );
        dispatch(userChosenContactName(name));
        dispatch(userChosenRelationDetails(draftRepair.reportedBy));
        dispatch(
          userChosenContactDetails(
            "I would like to provide number for this repair."
          )
        );
      } else {
        //dispatch(userChosenContactDetails(draftRepair.co));
        dispatch(userChosenRelationDetails("Customer"));
        dispatch(
          userChosenContactPhone(
            customerResponse?.addresses?.telecomsAddresses?.[0].number
          )
        );
        dispatch(
          userChosenContactDetails(
            customerResponse?.addresses?.telecomsAddresses?.[0].number
          )
        );
      }
      props.history.push("/confirmationdetails");
    } else {
      props.history.push("/uploadmedia");
    }
  };

  const rescheduleAppointmentHandler = () => {
    if (errorCodeReschedule === "CR166") {
      setIsReScheduleModalShow(true);
      return;
    } else {
      setIsReScheduleModalShow(false);
    }
    if (isRescheduleShow === "Block") {
      setIsRescheduleShow("none");
    }
    else {
      if (errorCodeReschedule === "SSR071" || errorCodeReschedule === "SSR075") {
        let message = t(errorCodeReschedule);
        message = message.replace("{0}", contractorname);
        message = message.replace("{1}", contractorphone);
        setRescheduleMessage(message);
      }
      else if (errorCodeReschedule === "SSR070") {
        const message = t("SSR070");
        setRescheduleMessage(message);
      }
      else {
        if (!isNullOrUndefined(errorCodeReschedule)) {
          setIsRescheduleShow("Block");
          return;
        }
        const historyData = CreateHistoryData("RescheduleAppointment");
        dispatch(repairHistoryData(historyData));
        props.history.push("/appointmentscheduler");
      }
      setIsRescheduleShow("Block");
    }
  }

  const CreateHistoryData = (operation: string) => {
    return {
      repairHistoryOperation: operation,
      repairHistoryWorkOrderID: repairHistoryDataValue.RepairHistoryworkOrderID,
      repairHistoryContractor: repairHistoryDataValue.RepairHistoryContractor,
      repairHistoryContractorSitesIdentifier: contractorSitesIdentifierValue
    };
  };

  const setCancelMessages = (error: string) => {
    if (error == "SSR036") {
      seterrorMessage(t("SSR036i"))
    }
    else if (error == "SSR066") {
      seterrorMessage(t("SSR066ii"))
    }
    else if (error == "SSR008") {
      seterrorMessage(t("SSR008ii"))
    }
  }

  //#endregion


  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        //logic for buttons 
        callPageHandler(repairHistoryDataValue.repairHistoryWorkOrderID)
        dispatch(selectedWorkorderForMedia(repairHistoryDataValue.repairHistoryWorkOrderID));
        //end logic for buttons
        const allItems: any = [];
        const repairHistory: any = await api.getRepairHistories(
          repairHistoryDataValue.repairHistoryWorkOrderID);
        Promise.all([repairHistory]).then((results) => {
          setIsLoading(false);
          results.map((items) => {
            items.map((eachItem: any) => {
              const emergencyStatus =
                repairHistoryDataValue.repairHistoryPriority === "Emergency" ||
                  repairHistoryDataValue.repairHistoryPriority ===
                  "Emergency (Out of hours)"
                  ? true
                  : false;
              const appointmentDate =
                eachItem.appointmentStartDatetime
                  ? moment(
                    eachItem.appointmentStartDatetime
                  ).format("MMMM Do YYYY, h:mm:ss A")
                  : "";

              let APCREATE01_Comment_Formatted = t("APCREATE01_Comment");
              APCREATE01_Comment_Formatted = APCREATE01_Comment_Formatted.replace("{0}", eachItem.appointmentStartDatetime ? moment(eachItem.appointmentStartDatetime).format("MMMM Do YYYY") : "")
                .replace("{1}", eachItem.appointmentStartDatetime ? moment(eachItem.appointmentStartDatetime).format("h:mm A") : "")
                .replace("{2}", eachItem.appointmentEndDatetime ? moment(eachItem.appointmentEndDatetime).format("h:mm A") : "");

              const allItemsObj: any = {
                Unappointed: {
                  statusTitle: t("Unappointed_Title"),
                  comments: emergencyStatus
                    ? t("Unappointed_Comment_Emergency")
                    : t("Unappointed_Comment"),
                },
                Appointed: {
                  statusTitle: t("Appointed_Title"),
                  comments: `${t("Appointed_Comment")} ${appointmentDate}`,
                },
                APCREATE01: {
                  statusTitle: t("APCREATE01_Title"),
                  comments: `${APCREATE01_Comment_Formatted}`,
                },
                APCREATE10: {
                  statusTitle: t("APCREATE10_Title"),
                  comments: `${t("APCREATE10_Comment1")}`,
                },
                AUPDATE01: {
                  statusTitle: t("AUPDATE01_Title"),
                  comments: `${t("AUPDATE01_Comment1")} ${appointmentDate}. ${t(
                    "AUPDATE01_Comment2"
                  )}`,
                },
                AUPDATE02: {
                  statusTitle: t("AUPDATE02_Title"),
                  comments: `${t("AUPDATE02_Comment1")} ${appointmentDate}. ${t(
                    "AUPDATE02_Comment2"
                  )}`,
                },
                AUPDATE03: {
                  statusTitle: t("AUPDATE03_Title"),
                  comments: `${t("AUPDATE03_Comment1")} ${appointmentDate}. ${t(
                    "AUPDATE03_Comment2"
                  )}`,
                },
                AUPDATE05: {
                  statusTitle: t("AUPDATE05_Title"),
                  comments: `${t("AUPDATE05_Comment1")} ${appointmentDate}. ${t(
                    "AUPDATE05_Comment2"
                  )}`,
                },
                AUPDATE06: {
                  statusTitle: t("AUPDATE06_Title"),
                  comments: `${t("AUPDATE06_Comment1")} ${appointmentDate}. ${t(
                    "AUPDATE06_Comment2"
                  )}`,
                },
                AUPDATE07: {
                  statusTitle: t("AUPDATE07_Title"),
                  comments: `${t("AUPDATE07_Comment1")} ${appointmentDate}. ${t(
                    "AUPDATE07_Comment2"
                  )}`,
                },
                NOACCESS: {
                  statusTitle: t("NOACCESS_Title"),
                  comments: t("NOACCESS_Comment"),
                },
                ENGOS01: {
                  statusTitle: t("ENGOS01_Title"),
                  comments: t("ENGOS01_Comment"),
                },
                ENGOS03: {
                  statusTitle: t("ENGOS03_Title"),
                  comments: t("ENGOS03_Comment"),
                },
                NOACC01: {
                  statusTitle: t("NOACC01_Title"),
                  comments: t("NOACC01_Comment"),
                },
                ENGOS05: {
                  statusTitle: t("ENGOS05_Title"),
                  comments: t("ENGOS05_Comment"),
                },
                ENGOS06: {
                  statusTitle: t("ENGOS06_Title"),
                  comments: t("ENGOS06_Comment"),
                },
                ENGOS07: {
                  statusTitle: t("ENGOS07_Title"),
                  comments: t("ENGOS07_Comment"),
                },
                Completed: {
                  statusTitle: t("Completed_Title"),
                  comments: t("Completed_Comment"),
                },
                Cancelled: {
                  statusTitle: t("Cancelled_Title"),
                  comments: t("Cancelled_Comment"),
                },
              };

              const itemObject = {
                statusTitle:
                  allItemsObj[eachItem.identifier]?.statusTitle || "",
                comments: allItemsObj[eachItem.identifier]?.comments || "",
                createDateTime: eachItem.createDateTime,
              };
              if (eachItem.subType !== "WorkOrder") {
                allItems.push(itemObject);
              }
            });
            const filteredItems = allItems.filter(
              (item: any) => item.statusTitle !== ""
            );

            const repairStatus = repairHistoryDataValue.RepairHistorystatus === RepairStatusField.ReportedToEMA ? t("ReportedToEMA_Title"): filteredItems?.length > 0 ? filteredItems[0]?.statusTitle : "Draft";
            setMostRecentStatus(repairHistoryDataValue.RepairHistorystatus === 'On Hold' ? 'On Hold' :
              repairStatus);
            setMostRecentStatusClass(
              filteredItems[0]?.statusTitle === "Repair cancelled" ? "status-style-red" :
                filteredItems[0]?.statusTitle === "Repair completed" ? "status-style-green" :
                  "status-style-yellow"
            )
            setmostRecentStatusMobileClass(
              filteredItems[0]?.statusTitle === "Repair cancelled" ? "mobile-repaircreate-red" :
                filteredItems[0]?.statusTitle === "Repair completed" ? "mobile-repaircreate-green" :
                  "mobile-repaircreate-yellow"
            )
            setRepairHistoryDetails(filteredItems);
          });
        });

        //set media section data

      } catch (e) {
        console.log(e);
        props.history.push("/genericerror");
      }
    })();
  }, []);

  const setContractorName = () => {
    let contractorName = ""
    if (repairHistoryDataValue.RepairHistoryContractor) {
      contractorName = repairHistoryDataValue.RepairHistoryContractor
    }
    else if (repairHistoryDataValue.RepairHistoryrepairReference && !repairHistoryDataValue.RepairHistoryContractor) {
      contractorName = t("contractor_unknown")
    }
    else {
      contractorName = "-"
    }
    return contractorName
  }

  return (
    <>
    <ListingDetailHeader name={"Listing"}></ListingDetailHeader>
    
    <div role="main" className="position-relative padding-repair mobile-view">

      {/* buttons section  */}
      <div
        id="hisRepDet-main-btn"
        className="appoint-btn appoint-desktop"
      >
        {repairHistoryDataValue.RepairHistorystatus === "In Diagnosis" ? (
          <Button
            className="primary-btn"
            data-testid="btn_Resume_repair"
            id="hisRepDet-btn1"
            onClick={resumeRepairHandler}
          >
            {t("Resume_repair")}
          </Button>
        ) : (
          <><span style={{ display: isCancelButton }}>
            <CancelModelRepair
              id="repreason-exitmodal"
              workOrderId={repairHistoryDataValue.RepairHistoryworkOrderID}
              area={repairHistoryDataValue.RepairHistoryarea}
              errorCode={errorCode}
              props={props} />
          </span><span style={{ display: isAppointmentAvailable }}>

              <Button
                className="primary-btn"
                data-testid="btn_Select_Appointment"
                id="hisRepDet-btn3"
                onClick={selectAppointmentHandler}
              >
                {t("Select_Appointment")}
              </Button>
            </span><span style={{ display: isRescheduleButton }}>
              <Button
                data-testid="btn_Reschedule"
                id="hisRepDet-btn4"
                className="primary-btn"
                onKeyDown={rescheduleAppointmentHandler}
                onClick={rescheduleAppointmentHandler}
              >
                {t("Reschedule_Appointment")}
              </Button>
            </span></>
        )}
      </div>


      <div className={mostRecentStatusMobileClass}>
        <h2 className="rephistory-heading d-inline">
          <span id="backmodal-p" className="back-page">
            <a data-testid="btn_back_repair" className="back-btn" onClick={backButtonHandler}>
              <img src={Chevronleft} className="large-leftarrow" alt="Filter" />
              <img src={mobileChevronleft} className="small-leftarrow detail-backarrow" alt="Filter" />
            </a>
          </span>
          {repairHistoryDataValue.RepairHistoryrepairReference}
        </h2>

        <span className={mostRecentStatusClass}>
          {repairHistoryDataValue.RepairHistorystatus === "In Diagnosis" ? "Draft" : mostRecentStatus}
        </span>

      </div>

      {/* buttons section  */}
      <div
        id="hisRepDet-main-btn"
        className="appoint-btn appoint-mobile"
      >
        {repairHistoryDataValue.RepairHistorystatus === "In Diagnosis" ? (
          <Button
            className="primary-btn"
            data-testid="btn_Resume_repair"
            id="hisRepDet-btn1"
            onClick={resumeRepairHandler}
          >
            {t("Resume_repair")}
          </Button>
        ) : (
          <>

            <span style={{ display: isAppointmentAvailable }}>

              <Button
                className="primary-btn"
                data-testid="btn_Select_Appointment"
                id="hisRepDet-btn3"
                onClick={selectAppointmentHandler}
              >
                {t("Select_Appointment")}
              </Button>
            </span>
            <span style={{ display: isRescheduleButton }}>

              <Button
                data-testid="btn_Reschedule"
                id="hisRepDet-btn4"
                className="primary-btn"
                onKeyDown={rescheduleAppointmentHandler}
                onClick={rescheduleAppointmentHandler}
              >
                {t("Reschedule_Appointment")}
              </Button>
            </span>
            <span style={{ display: isCancelButton }}>
              <CancelModelRepair
                id="repreason-exitmodal"
                workOrderId={repairHistoryDataValue.RepairHistoryworkOrderID}
                area={repairHistoryDataValue.RepairHistoryarea}
                errorCode={errorCode}
                props={props}
              />
            </span>
          </>
        )}
      </div>

      <div className="row repair-details padding-repair">
        {isReScheduleModal && (
          <ReScheduleModelRepair
            setIsReScheduleModalShow={setIsReScheduleModalShow}
          />
        )}

          {
            repairHistoryDataValue.RepairHistoryContractor === 'ContractorSiteOnHold' &&
            (
              <p id='onHoldRepairMsg'>
                <Message className='info-msg'>
                  {t('onHoldRepairHistoryDetailsMessage')}
                </Message>
              </p>
            )
          }

        
          {isSelectAppointmentChosen && woHadDemobilizedContractorSite && (<p id="warningMsg">
                <Message className="warning-msg">
                 <div>{t("please_contact")} {contractorDetails?.name} {t("on_phone")} {contractorContactNumber} {t("book_appointment")}</div>
                  </Message>
                  </p>)
          }
    
        {
          errorMessage != "" && errorCode != "" && (
            <p id="cancelMsg">
              <Message className="info-msg">
                {errorMessage}
              </Message>

            </p>)

        }

        <div className="p-0" style={{ display: isRescheduleShow }}>
          <p id="resheduleMsg">
            <Message className="info-msg">
              {rescheduleMessage}
            </Message>
          </p>
        </div>

        <div className="col-md-6 col-sm-12 descr-padding">
          <h6 className="pb-6">{t("historic_repairs_description")}</h6>
          <p className="pb-12">{repairHistoryDataValue.RepairHistoryDescription}</p>
          <h6 className="pb-6">{t("historic_repairs_dates")}</h6>
          <p className="pb-12">{repairHistoryDataValue.RepairHistoryRaisedDate}</p>

          <h6 className="pb-6">{t("historic_repairs_area")}</h6>
          <p className="pb-12">{repairHistoryDataValue.RepairHistoryarea}</p>
        </div>
        <div className="col-md-6 appoint-section">
          <h5>{t("historic_repairs_appointment")}
          </h5>
          <h6 className="pb-6">{t("historic_repairs_appointment")}</h6>
            <p className="pb-12">
            {(appointmentStartDate?.length >0) ? moment(appointmentStartDate).format('DD-MM-YYYY, h.mma') : "-"}
                </p>
          
          <h6 className="pb-6">{t("historic_repairs_priority")}</h6>
          <p className="pb-12">{repairHistoryDataValue.RepairHistoryPriority}</p>
          <h6 className="pb-6">{t("historic_repairs_contractor")}</h6>
          <p className="pb-12">{setContractorName()}</p>


        </div>
      </div>

      <div className="history-section padding-repair">
          <h5>
            {t("History")}
          </h5>
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color="#00BFFF"
            height={70}
            width={50}
            timeout={5000}
          />
        ) : !repairHistoryDetails?.length || !mostRecentStatus ? (
          <p className="pb-7">{t("repairs_history_no_results")}</p>
        ) : (
          <p>
          <Message className="info-msg">
            {t("SSR072")}
          </Message>

        </p>
        )}
        {
          repairHistoryDetails.map(
            (eachRepairUpdate: RepairHistory, key: any) => {
              return (
                <div key={key}>
                  <h6 className="pb-7">{eachRepairUpdate.statusTitle}</h6>
                  <p> {eachRepairUpdate.comments} </p>
                  <p className="repaircreate-date">
                    {moment(eachRepairUpdate.createDateTime).format(
                      "MMMM Do YYYY, h:mm:ss A"
                    )}
                  </p>
                </div>
              );
            }
          )

        }
      </div>
      {isMedia && communalToggle === "true" && (
        <div className="row history-section padding-repair">
          <div className="col-md-12 media-section">
            <MediaList
              wOid={repairHistoryDataValue.repairHistoryWorkOrderID}
              {...props}
            ></MediaList>
          </div>
        </div>
      )}

      {isMedia &&
        communalToggle === "false" &&
        _customerId === mediaOwner && (
          <div className="row history-section padding-repair">
            <div className="col-md-12 media-section">
              <MediaList
                wOid={repairHistoryDataValue.repairHistoryWorkOrderID}
                {...props}
              ></MediaList>
            </div>
          </div>
        )}

    </div>
    </>
  );
};

export default RepairHistoryDetails;
